// @ts-nocheck
import { Component, ElementRef, AfterViewInit, NgZone, ViewChild, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements AfterViewInit, OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'Explore our projects and envision the future of your business with us,bilwg services pvt ltd,Bilwg Projects' },
      { name: 'author', content: 'Bilwg Services Pvt Ltd' },
      { name: 'robots', content: 'index, follow' },
      { name: 'date', content: '2023-09-23', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'revisit_after', content: '15 days' },
      { name: 'language', content: 'English' },
      { name: 'keywords', content: 'bilwg services portfolio,bilwg projects,software development project,projects software development,app development projects,android app development project,iOS app development project,website development project,website development agency,best development agency,bilwg services pvt ltd,software development,android app,software,build website grow business,bilwg sercies pvt ltd' }
    ]);
    this.title.setTitle('Portfolio');
  }

  ngOnInit() {
    // const controller = new ScrollMagic.Controller();

    // const tween = new TimelineMax();
    // tween.from('.image-container', 1, { y: "-20%", ease: Power0.easeInOut })

    // new ScrollMagic.Scene({
    //   triggerElement: '.main-body',
    //   duration: '200%',
    //   triggerHook: 0,
    // })
    // .setTween(
    //   tween
    // )
    // .setPin('.main-body')
    // .addTo(controller); // Add the scene to the controller
  }


}
