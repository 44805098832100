<div class="main-page">
    <app-cursor-tracker></app-cursor-tracker>
    <app-nav-bar-black></app-nav-bar-black>
    <!--Start: Pricing Section-->
    <section class="our-pricing">
        <h1>Our Pricing</h1>
        <p class="p">Embark on your software development journey with confidence. Our transparent pricing and commitment
            to excellence set us apart. Contact us today for a detailed consultation and let's bring your vision to
            life.</p>
    </section>
    <!--End: Pricing SEction-->
    <section class="service-price-section">
        <article class="service-price">
            <div class="description">
                <div class="name-star">
                    <img src="assets/image/icon/star.png" alt="">
                    <h2 class="service-name">Design</h2>
                </div>
                <p class="service-des">Design services are crucial for businesses seeking to make a lasting impact. A
                    well-crafted visual identity not only captures attention but also conveys professionalism and trust.
                    Consistent, appealing design across all touchpoints builds brand recognition and fosters customer
                    loyalty. It enhances user experience, making interactions seamless and memorable. Investing in
                    design services is a vital step towards establishing a strong, enduring brand presence in the
                    market.</p>
            </div>

            <table class="price-table">
                <thead>
                    <tr class="underline">
                        <td class="td th">Position</td>
                        <td class="td th">Tools</td>
                        <td class="td th">Type</td>
                        <td class="td th">Starting</td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="td">Ui Design</td>
                        <td class="td">Figma</td>
                        <td class="td">Monthly</td>
                        <td class="td">$480</td>
                    </tr>
                    <tr>
                        <td class="td">Logo</td>
                        <td class="td">Illustrator</td>
                        <td class="td">Per Item</td>
                        <td class="td">$60</td>
                    </tr>
                    <tr>
                        <td class="td">Basic Pages (Settings, About,
                            Cart, Profile)</td>
                        <td class="td">Figma</td>
                        <td class="td">Per Item</td>
                        <td class="td">$25</td>
                    </tr>
                    <tr>
                        <td class="td">Normal Pages (On-boarding, Login & Signup, )</td>
                        <td class="td">Figma</td>
                        <td class="td">Per Item</td>
                        <td class="td">$40</td>
                    </tr>

                    <tr>
                        <td class="td">Advance Pages (Homepage & Product(’s) page)</td>
                        <td class="td">Figma</td>
                        <td class="td">Per Item</td>
                        <td class="td">$60</td>
                    </tr>
                </tbody>

            </table>

        </article>

        <article class="service-price">
            <div class="description">
                <div class="name-star">
                    <img src="assets/image/icon/star2.png" alt="">
                    <h2 class="service-name">Development</h2>
                </div>
                <p class="service-des">Comprehensive software development services, including web and app development,
                    are the lifeblood of modern businesses. They empower companies to streamline operations, automate
                    tasks, and remain agile in a rapidly evolving market. Tailored web and app solutions cater to
                    specific needs, enhancing efficiency and productivity while providing a seamless user experience.
                    Moreover, these services ensure data security and compliance, safeguarding sensitive information. In
                    an era where technology drives progress, investing in expert software, web, and app development
                    services is not only a strategic move but a necessity for businesses aiming to stay competitive,
                    innovate, and scale effectively.</p>
            </div>


            <table class="price-table">
                <thead>
                    <tr class="underline">
                        <td class="td th">Position</td>
                        <td class="td th">Tools</td>
                        <td class="td th">Type</td>
                        <td class="td th">Starting</td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="td">Ui Developer</td>
                        <td class="td">Front End - (Html, Css, Javascript)</td>
                        <td class="td">Monthly</td>
                        <td class="td">$480</td>
                    </tr>
                    <tr>
                        <td class="td">Front End Web Developer</td>
                        <td class="td">Angular, React</td>
                        <td class="td">Monthly</td>
                        <td class="td">$600</td>
                    </tr>
                    <tr>
                        <td class="td">Back End Web Developer</td>
                        <td class="td">Nodejs, Go, PHP</td>
                        <td class="td">Monthly</td>
                        <td class="td">$725</td>
                    </tr>

                    <tr>
                        <td class="td">Full Stack Developer (Web)</td>
                        <td class="td">Angular, React + Nodejs, Go, PHP</td>
                        <td class="td">Monthly</td>
                        <td class="td">$960</td>
                    </tr>

                    <tr>
                        <td class="td">Android App Developer</td>
                        <td class="td">Kotlin, Java</td>
                        <td class="td">Monthly</td>
                        <td class="td">$850</td>
                    </tr>

                    <tr>
                        <td class="td">iOS App Developer</td>
                        <td class="td">Swift, Swift UI</td>
                        <td class="td">Monthly</td>
                        <td class="td">$850</td>
                    </tr>

                    <tr>
                        <td class="td">Hybrid App Developer</td>
                        <td class="td">Flutter, React Native</td>
                        <td class="td">Monthly</td>
                        <td class="td">$960</td>
                    </tr>

                    <tr>
                        <td class="td">CMS Developer</td>
                        <td class="td">Wordpress</td>
                        <td class="td">Monthly</td>
                        <td class="td">$360</td>
                    </tr>

                    <tr>
                        <td class="td">Developer</td>
                        <td class="td">Flutter, Angular, React, NodeJS, GO, PHP</td>
                        <td class="td">Hourly</td>
                        <td class="td">$15</td>
                    </tr>

                    <tr>
                        <td class="td">Developer</td>
                        <td class="td">Java, Kotlin, Swift, .Net</td>
                        <td class="td">Hourly</td>
                        <td class="td">$20</td>
                    </tr>
                </tbody>


            </table>


        </article>

        <article class="service-price">
            <div class="description">
                <div class="name-star">
                    <img src="assets/image/icon/star3.png" alt="">
                    <h2 class="service-name">Testing</h2>
                </div>
                <p class="service-des">Software testing services are crucial for a seamless and secure digital
                    experience. They guarantee applications and systems function flawlessly, preventing costly errors or
                    security breaches. By identifying issues early, they save time and resources. Investing in
                    professional testing ensures the dependability and success of any digital venture in today's
                    competitive landscape.</p>
            </div>

            <table class="price-table">
                <thead>
                    <tr class="underline">
                        <td class="td th">Position</td>
                        <td class="td th">Tools</td>
                        <td class="td th">Type</td>
                        <td class="td th">Starting</td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="td">Functional</td>
                        <td class="td">Windows (Laptop, Desktop), Mac (Laptop, Desktop), Android and iOS</td>
                        <td class="td">Monthly</td>
                        <td class="td">$150</td>
                    </tr>
                    <tr>
                        <td class="td">End to end</td>
                        <td class="td">Windows (Laptop, Desktop), Mac (Laptop, Desktop), Android and iOS</td>
                        <td class="td">Monthly</td>
                        <td class="td">$250</td>
                    </tr>
                    <tr>
                        <td class="td">Performance & Browser compatibility Testing</td>
                        <td class="td">BrowserStack</td>
                        <td class="td">Monthly</td>
                        <td class="td">$180</td>
                    </tr>

                    <tr>
                        <td class="td">Usability & Responsiveness</td>
                        <td class="td">GUI, Regression, Cross-device testing</td>
                        <td class="td">Monthly</td>
                        <td class="td">$99</td>
                    </tr>
                    <tr>
                        <td class="td">Exploratory, Smoke, Sanity, Ad-hoc</td>
                        <td class="td">Postman, Selenium, SBTM, SBET & Bug Tracking Tools</td>
                        <td class="td">Monthly</td>
                        <td class="td">$99</td>
                    </tr>
                </tbody>

            </table>

        </article>

        <article class="service-price">
            <div class="description">
                <div class="name-star">
                    <img src="assets/image/icon/star4.png" alt="">
                    <h2 class="service-name">Marketing and SEO</h2>
                </div>
                <p class="service-des">Marketing and SEO services are the backbone of a business's online presence. They
                    ensure your brand reaches the right audience and ranks high in search results, driving organic
                    traffic and conversions. In today's digital landscape, investing in these services is essential for
                    staying competitive and thriving in the market.</p>
            </div>

            <table class="price-table">
                <thead>
                    <tr class="underline">
                        <td class="td th">Position</td>
                        <td class="td th">Tools</td>
                        <td class="td th">Type</td>
                        <td class="td th">Starting</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="td">Branding</td>
                        <td class="td">Adobe Illustrator, Figma, Photoshop, Premiere Pro
                            & After Effects
                        </td>
                        <td class="td">One Time</td>
                        <td class="td">$360</td>
                    </tr>
                    <tr>
                        <td class="td">Social Media Marketing</td>
                        <td class="td">Facebook, Instagram, Linkedin, Twitter & Youtube</td>
                        <td class="td">Monthly</td>
                        <td class="td">$250</td>
                    </tr>
                    <tr>
                        <td class="td">Content Marketing</td>
                        <td class="td">-</td>
                        <td class="td">Per Post</td>
                        <td class="td">$15</td>
                    </tr>

                    <tr>
                        <td class="td">Paid Social Prospecting</td>
                        <td class="td">Facebook, Instagram, Linkedin, Twitter & Youtube</td>
                        <td class="td">Monthly</td>
                        <td class="td">$150</td>
                    </tr>

                    <tr>
                        <td class="td">Branded & Non-Branded Search (PPC)</td>
                        <td class="td">Facebook, Linkedin, Google, Youtube and App Store</td>
                        <td class="td">Monthly</td>
                        <td class="td">$180</td>
                    </tr>

                    <tr>
                        <td class="td">Search Engine Optimization</td>
                        <td class="td">Google Search</td>
                        <td class="td">Monthly</td>
                        <td class="td">$99</td>
                    </tr>

                    <tr>
                        <td class="td">Store Optimization</td>
                        <td class="td">Amazon, App Store and Play Store</td>
                        <td class="td">Monthly</td>
                        <td class="td">$149</td>
                    </tr>
                </tbody>

            </table>


        </article>

        <article class="service-price">
            <div class="description">
                <div class="name-star">
                    <img src="assets/image/icon/star5.png" alt="">
                    <h2 class="service-name">Maintenance</h2>
                </div>
                <p class="service-des">
                    Software maintenance services are essential for a robust digital infrastructure. They guarantee
                    optimal performance, adaptability, and security for your applications and systems. Regular updates
                    and bug fixes protect against potential threats and ensure a seamless user experience, ultimately
                    saving time and resources in the long run.</p>
            </div>

            <table class="price-table">

                <thead>
                    <tr class="underline">
                        <td class="td th">Position</td>
                        <td class="td th">Tools</td>
                        <td class="td th">Type</td>
                        <td class="td th">Starting</td>
                    </tr>
                </thead>
                <tbody>


                    <tr>
                        <td class="td">Server Management</td>
                        <td class="td">Nginx, Apache, Control Panel</td>
                        <td class="td">Monthly</td>
                        <td class="td">$100</td>
                    </tr>
                    <tr>
                        <td class="td">Codebase Management + Minor Changes</td>
                        <td class="td">Version Control & Programming Languages</td>
                        <td class="td">Codebase per Month</td>
                        <td class="td">$50</td>
                    </tr>
                    <tr>
                        <td class="td">App & Website Analytics Report</td>
                        <td class="td">-</td>
                        <td class="td">Monthly</td>
                        <td class="td">$120</td>
                    </tr>

                    <tr>
                        <td class="td">Tech Support</td>
                        <td class="td">Developer</td>
                        <td class="td">Monthly</td>
                        <td class="td">$120</td>
                    </tr>
                </tbody>
            </table>

        </article>
    </section>


    <app-footer></app-footer>

</div>