<app-nav-bar></app-nav-bar>

<app-cursor-tracker></app-cursor-tracker>

<article class="main-section">
    <section class="glass">
        <h1 class="heading">DESIGNING custom</h1>
        <p>With finest developers and graphic designers
            in our team, we have never disappointed our clients.</p>
        <h2 class="heading">software to</h2>

        <p class="creativebg">
            A creative design agency from INDIA
        </p>

        <h2 class="heading">your unique needs</h2>


        <div class="row">
            <p>Design Visually. <br> Create Efficiently.</p>

            <p class="right-text">Elevate Your Digital Vision with Bilwg Services <br> Where Innovation Meets Execution
                Message
            </p>
        </div>
    </section>
</article>

<!--TODO: Change the our-services icons for web and tablet-->
<div class="outerService">
    <article class="our-services">
        <section class="glass">
            <div class="single-service">
                <h2>Our <span>Services.</span> </h2>
                <p>Elevating Your Business with Comprehensive Services for Digital Excellence
                </p>
            </div>


            <div class="single-service">
                <img src="assets/image/icon/web.png" alt="Web Development Icon">
                <h2>Web Development</h2>
                <p>Our web development service is dedicated to crafting dynamic and user-centric websites that drive
                    your online presence to new heights. With a team of skilled developers, we tailor solutions to meet
                    your unique business needs, ensuring responsive design, seamless functionality, and a captivating
                    user experience.</p>
            </div>

            <div class="single-service">
                <img src="../../../assets/image/icon/app.png" alt="App Development Icon">
                <h2>App Development</h2>
                <p>At our app development service, we thrive on transforming your app concepts into reality. Our team of
                    passionate developers is dedicated to crafting innovative, user-centric mobile applications that
                    cater to your specific needs. We take pride in our ability to turn your ideas into intuitive,
                    feature-rich apps that resonate with your target audience.</p>
            </div>

            <div class="single-service">
                <img src="assets/image/icon/saas.png" alt="Saas/Custom Software Icon">
                <h2>Saas/Custom Software</h2>
                <p>When it comes to SaaS and custom software, we provide versatile solutions tailored precisely to your
                    requirements. Whether you're interested in a pre-built Software as a Service (SaaS) solution or a
                    bespoke software application, we harness state-of-the-art technology to realize your unique vision.
                </p>
            </div>
            <div class="single-service">
                <img src="assets/image/icon/graphics.png" alt="Graphics Design Icon">
                <h2>Graphics Design</h2>
                <p>Graphic design is our canvas for creative expression. Our team melds artistry and strategy to produce
                    visually distinct creations that resonate with your audience. From distinctive logos to standout
                    marketing collateral, we craft unique designs that tell your story and captivate viewers.</p>
            </div>


            <div class="single-service">
                <img src="assets/image/icon/seo.png" alt="Search Engine Optimization Icon">
                <h2>Search Engine Optimization</h2>
                <p>Our SEO services are your pathway to higher online visibility and
                    organic growth. We employ cutting-edge strategies to optimize your website's performance in search
                    engine rankings. From keyword research to on-page and off-page optimizations, we ensure that your
                    digital content is easily discoverable by your target audience.</p>
            </div>

            <div class="single-service">
                <img src="assets/image/icon/maintenance.png" alt="Maintenance Icon">
                <h2>Maintenance</h2>
                <p>Maintenance is a crucial aspect of ensuring the longevity and optimal performance of your digital
                    assets. Our maintenance services are designed to keep your websites, apps, or systems running
                    smoothly. We offer regular updates, security patches, and performance optimizations to mitigate
                    issues and enhance user experience.</p>
            </div>
            <div class="single-service">
                <img src="assets/image/icon/uiux.png" alt="UI/UX Design Icon">
                <h2>UI/UX Design</h2>
                <p>In UI/UX design, we specialize in creating intuitive and visually engaging interfaces. Our focus is
                    on seamlessly blending form and function to deliver exceptional user experiences. Whether you're
                    starting a project from scratch or need a design overhaul, we're here to make your digital vision
                    user-friendly and visually striking.</p>
            </div>

            <div class="single-service">
                <img src="assets/image/icon/graphics.png" alt="Graphics Design Icon">
                <h2>Social Media Mangement</h2>
                <p>Social media management is our forte, where we elevate your online presence. We strategize, curate
                    content, and engage with your audience to build a thriving online community. From content calendars
                    to analytics, we ensure your brand shines on platforms like Facebook, Instagram, and Twitter.</p>
            </div>
        </section>
    </article>
</div>

<!--Start: Testimonial Section-->
<!--TODO: Change pictures of people in testimonial section-->
<div class="outerTestimonial" id="testimonial">
    <section class="testimonials">
        <h2>Testimonials</h2>
        <p>Majority of our clients continued working with us even after the project was complete. The regular re-visits
            of our old clients and being recommended by them to others is a clear indicator of our quality work and
            ethics. Don't just take 'OUR' word for it, this is what some of our clients have to say.</p>

        <div class="row">
            <div class="single-testimonial">
                <div class="image-box">
                    <img src="assets/image/testimonials/user1.png" alt="User1">
                </div>

                <div class="text">
                    <h2>“Bilwg Services was great to work with”</h2>
                    <p>They were communicative, easy going and timely. They helped work within my budget and help
                        product a great application. They were available to fix bugs regularly and I appreciated all the
                        hard work they put it. Thanks! </p>
                    <div class="p2">Raphael Schmutzer</div>
                    <div class="designation">
                        (CEO - Habit Party)
                    </div>

                </div>
            </div>


            <div class="single-testimonial">
                <div class="image-box">
                    <img src="assets/image/testimonials/user2.png" alt="User2">
                </div>

                <div class="text">
                    <h2>“Fully satisfied with the services”</h2>
                    <p>We really liked the work done by Bilwg Services. we will give 5 Stars to them for completing our
                        project on time. We have hired them for blog and will definitely recommend them to our clients
                        for website services. Best regards!</p>
                    <div class="p2">Manoj Rawat</div>
                    <div class="designation">
                        (CA - Taxonut)
                    </div>
                </div>
            </div>


            <div class="single-testimonial">
                <div class="image-box">
                    <img src="assets/image/testimonials/user3.png" alt="User3">
                </div>

                <div class="text">
                    <h2>“Loved the overall workflow”</h2>
                    <p>I had some rough ideas about 'My Cycle Clinic' application. But things became more clear when
                        discussed the project with Bilwg. We had regular meetings and discussed about the app. Happy to
                        see the timely updates from their side. Loved the overall workflow. Thank you so much.</p>
                    <div class="p2">Cycle Clinic Inc</div>
                </div>
            </div>

            <div class="single-testimonial">
                <div class="image-box">
                    <img src="assets/image/testimonials/user4.png" alt="User1">
                </div>

                <div class="text">
                    <h2>“Innovative, reliable, and a true partner in progress”</h2>
                    <p>Bilwg has elevated our operations to new heights with their exceptional software solutions."</p>
                    <div class="p2">Sain Life</div>
                </div>
            </div>


        </div>
    </section>
</div>
<!--Start: Testimonial Section-->

<!--Start: Recent Project Section-->
<section class="recent-project">
    <div class="innerBox">
        <div class="row">
            <div class="box">
                <h2 class="heading">
                    Our Recent <br>
                    <span>Client Works.</span>
                </h2>
            </div>
            <div class="box">
                <p class="sub-head">Empowering Businesses and Transforming Industries with Innovative and Scalable
                    Software Solutions.
                </p>
            </div>
        </div>


        <div class="row">
            <div class="box img-box">
                <img src="assets/image/project/project-home-1.png" alt="Project 1">
            </div>
            <div class="box">
                <h2>
                    HabitParty
                </h2>
                <p>Habitparty helps you stay on top of your habits by creating friendly competitions. Competing with
                    friends helps make hitting your goals fun! Simply invite friends with the send of a text.

                    Use habitparty to compete with your friends to develop better habits. Habits include: Biking,
                    Dieting, Hydrating, Meditating, Reading, Running, Sleeping and Strength Training.</p>
            </div>
        </div>


        <div class="row">
            <div class="box img-box">
                <img src="assets/image/project/project-home-2.png" alt="Project 2">
            </div>

            <div class="box">
                <h2>
                    PGurus - Trusted News Source
                </h2>
                <p>We at PGurus are committed to providing you with a fair, unbiased and accurate analysis of India.
                    India is best understood by looking at its three facets – Business, Politics, and Culture as they
                    are interwoven. Our goal is to educate you, to point the pitfalls and gotchas and allow you to
                    decide where and when you want to invest. If you use our vehicles, we promise that we will treat
                    your money as our own and give it our undivided attention.</p>
            </div>

        </div>


    </div>
</section>

<!--End: Recent Project Section-->

<br><br><br><br>


<!--Start: Lets-talk section-->
<section class="lets-talk">
    <!--TODO: Change the lets-talk image-->
    <img src="assets/image/letstalk.svg" alt="Lets Talk">
</section>
<!--End: Lets-talk section-->

<!--Start: Contact Form -->
<section class="form-box" id="contact">

    <section class="outerBox">
        <section class="innerBox">
            <div class="left">
                <h3>Have an Idea?</h3>
                <p class="des">Looking for a partner who can help you turn your vision into reality? Contact us today to get
                    started.
                    Together, we can make your vision a reality.
                </p>
                <div class="socials disp">

                    <div class="row">
                        <img src="assets/image/icon/telephone.png" alt="bilwg services telephone">
                        <p>+91 817191923369</p>
                    </div>

                    <div class="row">
                        <img src="assets/image/icon/email.png" alt="bilwg services email">
                        <p>admin@bilwg.com</p>
                    </div>

                    <div class="row">
                        <img src="assets/image/icon/location.png" alt="bilwg services address">
                        <p>C/O Anjil Sah Sajan Lal, Dhungshil, Bhimtal, <br>
                          Nainital, Uttarakhand, 263136</p>
                    </div>
                </div>

                <div class="socialHandles disp">
                    <a href="https://facebook.com/bilwg" target="_blank">
                        <img class="socialHandleIcon" src="assets/image/icon/facebook.png" alt="Bilwg Services Facebook">
                    </a>

                    <a href="https://twitter.com/Bilwg_Services" target="_blank">
                        <img class="socialHandleIcon" src="assets/image/icon/twitter.png" alt="Bilwg Services Twitter">
                    </a>

                    <a href="https://in.linkedin.com/company/bilwg" target="_blank">
                        <img class="socialHandleIcon" src="assets/image/icon/linkedin.png" alt="Bilwg Services Linkedin">
                    </a>
                </div>
            </div>
            <div class="form">
                <form method="dialog" [formGroup]="contactFormGroup">
                    <div class="detail">
                        <label class="text">Your Name</label>
                        <input type="text" formControlName="name" name="name" id="name">
                    </div>
                    <div class="detail">
                        <label class="text">Mail</label>
                        <input type="email" formControlName="email" name="email" id="mail">
                    </div>
                    <div class="detail">
                        <label class="text">Phone Number</label>
                        <input type="tel" formControlName="phone" name="phone" id="pnumber">
                    </div>
                    <div class="detail">
                        <label class="text">Description</label>
                        <textarea name="description" formControlName="description" id="description" cols="30"
                            rows="10"></textarea>
                    </div>
                    <span class="error">{{$error}}</span>
                    <div class="button" (click)="onClickSend()">Submit
                        <img src="assets/image/icon/arrow.svg" alt="">
                    </div>

                </form>
            </div>
        </section>

        <div class="calendly-inline-widget" data-url="https://calendly.com/bilwg/getting-in-touch" style="min-width:320px;height:700px;"></div>
    </section>
</section>
<!--End: Contact Form-->


<!--Start: Bilwg Services Marquee-->

<div class="bilwg-services-bg-marquee"></div>

<!--End: Bilwg Services Marquee -->

<app-footer></app-footer>
