import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent {
  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'Our transparent pricing and commitment to excellence set us apart. Contact us today for a detailed consultation and let\'s bring your vision to life,bilwg services pvt ltd' },
      { name: 'author', content: 'Bilwg Services Pvt Ltd' },
      { name: 'robots', content: 'index, follow' },
      { name: 'date', content: '2023-09-23', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'revisit_after', content: '15 days' },
      { name: 'language', content: 'English' },
      { name: 'keywords', content: 'bilwg pricing,bilwg services pricing,pricing of bilwg services,software development pricing,website development pricing,app development pricing,android app development pricing,iOS app development pricing,design pricing,ui ux pricing,testing pricing,maintenance pricing,app developement pricing,website development,app development agency,bilwg services pvt ltd,software development,android app,bilwg services private limited,bilwg website,software developers,iOS app developers,android app developers,software,development company,nainital,uttarakhand,india' }
    ]);
    this.title.setTitle('Pricing');
  }
}
