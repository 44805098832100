import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cursor-tracker',
  templateUrl: './cursor-tracker.component.html',
  styleUrls: ['./cursor-tracker.component.scss']
})
export class CursorTrackerComponent {
  cursorX: number = -100;
  cursorY: number = -100;
  constructor(private router: Router) { }

  @ViewChild('translated', { static: true }) translatedTextElement: ElementRef | undefined;


  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    this.cursorX = e.clientX;
    this.cursorY = e.clientY;
    setTimeout(() => {
      if (this.router.url == "/") {
        this.translatedTextElement?.nativeElement.setAttribute('style', ` visibility: visible; transform: translate(${this.cursorX - 5}px, ${this.cursorY - 70}px);`);
      } else {
        this.translatedTextElement?.nativeElement.setAttribute('style', ` visibility: visible; transform: translate(${this.cursorX - 5}px, ${this.cursorY - 5}px);`);
      }
    }, 50);


  }
}
