<div class="main-body">

    <app-cursor-tracker></app-cursor-tracker>
    <app-nav-bar-black></app-nav-bar-black>

    <div class="body">
        <section class="header">
            <h1>Privacy Policy</h1>
            <p>Your Data, Your Rights: Our Commitment to Privacy and Security </p>
        </section>


        <section class="text">
            <div data-custom-class="body">
                <div><strong><span style="font-size: 26px;"><span data-custom-class="title">
                                <div class="block-component"></div>PRIVACY NOTICE<div class="else-block"></div>
                            </span></span></strong></div>
                <div><br></div>
                <div><span style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
                                    data-custom-class="subtitle">Last updated <div class="question">7 September 2023
                                    </div>
                                </span></span></strong></span></div>
                <div><br></div>
                <div><br></div>

                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);">
                        <span style="color: rgb(89, 89, 89); font-size: 15px;">
                            <span data-custom-class="body_text">This privacy notice for <div class="question">Bilwg
                                    Services Private Limited </div>
                                (“<strong>we</strong>,” “<strong>us</strong>,” or “<strong>our</strong>“</span>
                            <span data-custom-class="body_text"> ), describes how and why we might collect, store, use,
                                and/or share ( “<strong>process</strong>“ )
                                your information when you use our services (“<strong>Services</strong>“), such as when
                                you:
                            </span></span></span>
                    <div>

                        <div style="line-height: 1.5;">

                            <div style="line-height: 1.5;">
                                <span style="font-size: 15px;">
                                    <span style="color: rgb(127, 127, 127);">
                                        <span data-custom-class="body_text">
                                            <strong>Questions or concerns? </strong>Reading this privacy notice will
                                            help you understand your privacy rights and choices. If you do not agree
                                            with our policies and practices, please do not use our Services. If you
                                            still have any questions or concerns, please contact us at
                                            <span class="question">
                                                admin@bilwg.com.
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>

                            <div style="line-height: 1.5;"><strong><span style="font-size: 15px;"><span
                                            data-custom-class="heading_1">SUMMARY OF KEY POINTS</span></span></strong>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong><em>This summary provides key points from
                                                our
                                                privacy notice, but you can find out more details about any of these
                                                topics
                                                by clicking the link following each key point or by using
                                                our&nbsp;</em></strong></span></span><a data-custom-class="link"
                                    href="#toc" class="__mPS2id _mPS2id-h"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text"><strong><em>table of
                                                    contents</em></strong></span></span></a><span
                                    style="font-size: 15px;"><span data-custom-class="body_text"><strong><em>&nbsp;below
                                                to
                                                find the section you are looking for.</em></strong></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong>What personal information do we
                                            process?</strong> When you visit, use, or navigate our Services, we may
                                        process
                                        personal information depending on how you interact with us and the Services, the
                                        choices you make, and the products and features you use. Learn more
                                        about&nbsp;</span></span><a data-custom-class="link" href="#personalinfo"
                                    class="__mPS2id _mPS2id-h"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text">personal information
                                            you disclose to us</span></span></a><span
                                    data-custom-class="body_text">.</span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong>Do we process any sensitive personal
                                            information?</strong>
                                        <div class="block-component"></div>We do not process sensitive personal
                                        information.
                                        <div class="else-block"></div>
                                    </span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong>Do we receive any information from third
                                            parties?</strong>
                                        <div class="block-component"></div>We may receive information from public
                                        databases,
                                        marketing partners, social media platforms, and other outside sources. Learn
                                        more
                                        about&nbsp;
                                    </span></span><a data-custom-class="link" href="#othersources"
                                    class="__mPS2id"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text">information collected
                                            from other sources</span></span></a><span
                                    data-custom-class="body_text">.</span><span style="font-size: 15px;"><span
                                        data-custom-class="body_text">
                                        <div class="statement-end-if-in-editor"></div>
                                    </span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong>How do we process your
                                            information?</strong>
                                        We process your information to provide, improve, and administer our Services,
                                        communicate with you, for security and fraud prevention, and to comply with law.
                                        We
                                        may also process your information for other purposes with your consent. We
                                        process
                                        your information only when we have a valid legal reason to do so. Learn more
                                        about&nbsp;</span></span><a data-custom-class="link" href="#infouse"
                                    class="__mPS2id _mPS2id-h"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text">how we process your
                                            information</span></span></a><span data-custom-class="body_text">.</span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong>In what situations and with which <div
                                                class="block-component"></div>parties do we share personal
                                            information?</strong> We may share information in specific situations and
                                        with
                                        specific <div class="block-component"></div>third parties. Learn more
                                        about&nbsp;</span></span><a data-custom-class="link" href="#whoshare"
                                    class="__mPS2id _mPS2id-h"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text">when and with whom we
                                            share your personal information</span></span></a><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">.<div
                                            class="block-component"></div></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong>What are your rights?</strong> Depending
                                        on
                                        where you are located geographically, the applicable privacy law may mean you
                                        have
                                        certain rights regarding your personal information. Learn more
                                        about&nbsp;</span></span><a data-custom-class="link" href="#privacyrights"
                                    class="__mPS2id _mPS2id-h"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text">your privacy
                                            rights</span></span></a><span data-custom-class="body_text">.</span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong>How do you exercise your rights?</strong>
                                        The
                                        easiest way to exercise your rights is by
                                        submitting
                                        a &nbsp;</span></span>

                                <a data-custom-class="link"
                                    href="https://app.termly.io/notify/6580521d-b35e-4c81-a8f1-47dee995bbda"
                                    rel="noopener noreferrer" target="_blank"><span style="font-size: 15px;">
                                        <span data-custom-class="body_text">
                                            data subject access request
                                        </span>
                                    </span>
                                </a>
                                <span style="font-size: 15px;">
                                    <span data-custom-class="body_text">, or by contacting us. We will
                                        consider and act upon any request in accordance with applicable data protection
                                        laws.
                                    </span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text">Want to learn more about what we do with any
                                        information we collect?&nbsp;</span></span><a data-custom-class="link"
                                    href="#toc" class="__mPS2id _mPS2id-h"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text">Review
                                            the privacy notice in full</span></span></a><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">.</span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="toc" style="line-height: 1.5;" class="_mPS2id-t"><span
                                    style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
                                            style="color: rgb(0, 0, 0);"><strong><span
                                                    data-custom-class="heading_1">TABLE OF
                                                    CONTENTS</span></strong></span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#infocollect" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);">1. WHAT INFORMATION DO WE
                                            COLLECT?</span></a></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#infouse" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);">2. HOW DO WE PROCESS YOUR
                                            INFORMATION?<div class="block-component"></div></span></a></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><a data-custom-class="link" href="#whoshare"
                                            class="__mPS2id _mPS2id-h">3. WHEN
                                            AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span><span
                                        data-custom-class="body_text">
                                        <div class="block-component"></div>
                                    </span><span style="color: rgb(127, 127, 127);"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                                    style="color: rgb(89, 89, 89);">
                                                    <div class="block-component"></div>
                                                </span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#cookies" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);">4. DO WE USE COOKIES AND OTHER
                                            TRACKING TECHNOLOGIES?</span></a><span
                                        style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                    <div class="statement-end-if-in-editor"></div>
                                                </span></span><span data-custom-class="body_text"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            style="color: rgb(89, 89, 89);">
                                                            <div class="block-component"></div>
                                                        </span></span></span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#sociallogins" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                                                    style="color: rgb(89, 89, 89);">5. HOW
                                                    DO WE HANDLE YOUR SOCIAL LOGINS?</span></span></span></a><span
                                        style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            style="color: rgb(89, 89, 89);">
                                                            <div class="statement-end-if-in-editor"></div>
                                                        </span></span>
                                                    <div class="block-component"></div>
                                                </span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#intltransfers" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);">6. IS YOUR INFORMATION
                                            TRANSFERRED INTERNATIONALLY?</span></a><span
                                        style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                    <div class="statement-end-if-in-editor"></div>
                                                </span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#inforetain" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);">7. HOW LONG DO WE KEEP YOUR
                                            INFORMATION?</span></a><span style="color: rgb(127, 127, 127);"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        style="color: rgb(89, 89, 89);">
                                                        <div class="block-component"></div>
                                                        <div class="block-component"></div>
                                                    </span></span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#infominors" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);">8. DO WE COLLECT
                                            INFORMATION FROM MINORS?</span></a><span
                                        style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                    <div class="statement-end-if-in-editor"></div>
                                                </span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><a data-custom-class="link"
                                            href="#privacyrights" class="__mPS2id _mPS2id-h">9.
                                            WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#DNT" class="__mPS2id _mPS2id-h"><span style="color: rgb(89, 89, 89);">10.
                                            CONTROLS FOR DO-NOT-TRACK
                                            FEATURES</span></a></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#caresidents" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);">11. DO CALIFORNIA
                                            RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span>
                                <div class="block-component"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text"></span></span></div>
                            </div>
                            <div style="line-height: 1.5;">
                                <div class="block-component"><span style="font-size: 15px;"></span></div>
                                <div class="block-component"></div>
                                <div class="block-component"></div>
                                <div class="block-component"></div>
                                <div class="block-component"></div>
                                <div class="block-component"></div>
                                <div class="block-component"></div>
                                <div class="block-component"></div>
                                <div class="block-component"></div>
                                <div class="block-component"></div>
                            </div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                                        href="#policyupdates" class="__mPS2id _mPS2id-h"><span
                                            style="color: rgb(89, 89, 89);">12. DO WE MAKE UPDATES
                                            TO THIS NOTICE?</span></a></span></div>
                            <div style="line-height: 1.5;"><a data-custom-class="link" href="#contact"
                                    class="__mPS2id _mPS2id-h"><span
                                        style="color: rgb(89, 89, 89); font-size: 15px;">13. HOW CAN YOU CONTACT US
                                        ABOUT
                                        THIS NOTICE?</span></a></div>
                            <div style="line-height: 1.5;"><a data-custom-class="link" href="#request"
                                    class="__mPS2id _mPS2id-h mPS2id-clicked"><span style="color: rgb(89, 89, 89);">14.
                                        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                                        WE COLLECT FROM YOU?</span></a></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="infocollect" style="line-height: 1.5;" class="_mPS2id-t"><span
                                    style="color: rgb(127, 127, 127);"><span
                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                            data-custom-class="heading_1">1. WHAT INFORMATION DO WE
                                                            COLLECT?</span></strong></span></span></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="personalinfo" style="line-height: 1.5;" class="_mPS2id-t"><span
                                    data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
                                        style="font-size: 15px;"><strong>Personal information
                                            you disclose to us</strong></span></span></div>
                            <div>
                                <div><br></div>
                                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                data-custom-class="body_text"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><strong><em>In
                                                                    Short:</em></strong></span></span></span></span><span
                                                data-custom-class="body_text"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We
                                                                collect personal information that you provide to
                                                                us.</em></span></span></span></span></span></span></div>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">We collect personal information that you
                                            voluntarily provide to us when you <span style="font-size: 15px;">
                                                <div class="block-component"></div>
                                            </span>register on the Services,&nbsp;</span><span
                                            style="font-size: 15px;"><span data-custom-class="body_text"><span
                                                    style="font-size: 15px;">
                                                    <div class="statement-end-if-in-editor"></div>
                                                </span></span><span data-custom-class="body_text">express an interest in
                                                obtaining information about us or our products and Services, when you
                                                participate in activities on the Services, or otherwise when you contact
                                                us.</span></span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><span style="font-size: 15px;">
                                                <div class="block-component"></div>
                                            </span></span></span></span></div>
                            <div id="sensitiveinfo" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><strong>Sensitive Information.</strong>
                                        <div class="block-component"></div>We do not process sensitive information.
                                    </span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text">
                                        <div class="else-block"></div>
                                    </span></span><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <div class="block-component">
                                                        <div class="block-component"></div>
                                                    </div>
                                                </span></span></span></span>
                                    <div class="block-component"></div>
                                </span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">All personal information that you provide to
                                            us
                                            must be true, complete, and accurate, and you must notify us of any changes
                                            to
                                            such personal information.</span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">
                                            <div class="block-component"></div>
                                        </span></span></span></div>
                            <div style="line-height: 1.5;"><span data-custom-class="heading_2"
                                    style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><strong>Information
                                            automatically collected</strong></span></span></div>
                            <div>
                                <div><br></div>
                                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                data-custom-class="body_text"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><strong><em>In
                                                                    Short:</em></strong></span></span></span></span><span
                                                data-custom-class="body_text"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>Some
                                                                information — such as your Internet Protocol (IP)
                                                                address
                                                                and/or browser and device characteristics — is collected
                                                                automatically when you visit our
                                                                Services.</em></span></span></span></span></span></span>
                                </div>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">We automatically collect certain information
                                            when
                                            you visit, use, or navigate the Services. This information does not reveal
                                            your
                                            specific identity (like your name or contact information) but may include
                                            device
                                            and usage information, such as your IP address, browser and device
                                            characteristics, operating system, language preferences, referring URLs,
                                            device
                                            name, country, location, information about how and when you use our
                                            Services,
                                            and other technical information. This information is primarily needed to
                                            maintain the security and operation of our Services, and for our internal
                                            analytics and reporting purposes.</span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">
                                            <div class="block-component"></div>
                                        </span></span></span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">Like many businesses, we also collect
                                            information
                                            through cookies and similar technologies. <div class="block-component">
                                            </div>
                                            <div class="block-component"></div>
                                        </span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">
                                            <div class="statement-end-if-in-editor"><span
                                                    data-custom-class="body_text"></span></div>
                                        </span><span data-custom-class="body_text">
                                            <div class="block-component"></div>
                                            <div class="statement-end-if-in-editor"></div>
                                        </span><span data-custom-class="body_text"><span
                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text"><span
                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                            data-custom-class="body_text">
                                                            <div class="statement-end-if-in-editor">
                                                                <div class="block-component"></div>
                                                            </div>
                                                        </span></span></span></span></span></span></span><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">
                                        <div class="block-component"></div>
                                    </span></span></div>
                            <div id="infouse" style="line-height: 1.5;" class="_mPS2id-t"><span
                                    style="color: rgb(127, 127, 127);"><span
                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                            data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR
                                                            INFORMATION?</span></strong></span></span></span></span></span>
                            </div>
                            <div>
                                <div style="line-height: 1.5;"><br></div>
                                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                data-custom-class="body_text"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><strong><em>In
                                                                    Short:&nbsp;</em></strong><em>We process your
                                                                information to provide, improve, and administer our
                                                                Services, communicate with you, for security and fraud
                                                                prevention, and to comply with law. We may also process
                                                                your
                                                                information for other purposes with your
                                                                consent.</em></span></span></span></span></span></span>
                                </div>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong>We process your personal information
                                                for a
                                                variety of reasons, depending on how you interact with our Services,
                                                including:</strong>
                                            <div class="block-component"></div>
                                        </span></span></span>
                                <div style="line-height: 1.5;"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">
                                                <div class="block-component"></div>
                                            </span></span></span>
                                    <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text">
                                                    <div class="block-component"></div>
                                                </span></span></span>
                                        <div style="line-height: 1.5;"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text">
                                                        <div class="block-component"></div>
                                                    </span></span></span>
                                            <div style="line-height: 1.5;"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text">
                                                            <div class="block-component"></div>
                                                        </span></span></span>
                                                <div style="line-height: 1.5;">
                                                    <div class="block-component"><span style="font-size: 15px;"></span>
                                                    </div>
                                                    <div style="line-height: 1.5;">
                                                        <div class="block-component"><span
                                                                style="font-size: 15px;"></span>
                                                        </div>
                                                        <div style="line-height: 1.5;">
                                                            <div class="block-component"><span
                                                                    style="font-size: 15px;"></span></div>
                                                            <div style="line-height: 1.5;">
                                                                <div class="block-component"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"></span></span>
                                                                </div>

                                                                <div style="line-height: 1.5;">
                                                                    <div class="block-component"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text"></span></span>
                                                                    </div>
                                                                    <div style="line-height: 1.5;">
                                                                        <div class="block-component"><span
                                                                                style="font-size: 15px;"></span></div>
                                                                        <div style="line-height: 1.5;">
                                                                            <div class="block-component"><span
                                                                                    style="font-size: 15px;"></span>
                                                                            </div>
                                                                            <div style="line-height: 1.5;"><span
                                                                                    style="font-size: 15px;">
                                                                                    <div class="block-component"><span
                                                                                            data-custom-class="body_text"></span>
                                                                                    </div>
                                                                                </span>
                                                                                <div style="line-height: 1.5;">
                                                                                    <div class="block-component"><span
                                                                                            style="font-size: 15px;"><span
                                                                                                data-custom-class="body_text"></span></span>
                                                                                    </div>
                                                                                    <div style="line-height: 1.5;">
                                                                                        <div class="block-component">
                                                                                            <span
                                                                                                style="font-size: 15px;"><span
                                                                                                    data-custom-class="body_text"></span></span>
                                                                                        </div>
                                                                                        <div style="line-height: 1.5;">
                                                                                            <div
                                                                                                class="block-component">
                                                                                                <span
                                                                                                    style="font-size: 15px;"><span
                                                                                                        data-custom-class="body_text"></span></span>
                                                                                            </div>
                                                                                            <div
                                                                                                style="line-height: 1.5;">
                                                                                                <div
                                                                                                    class="block-component">
                                                                                                    <span
                                                                                                        style="font-size: 15px;"><span
                                                                                                            data-custom-class="body_text"></span></span>
                                                                                                </div>
                                                                                                <div
                                                                                                    style="line-height: 1.5;">
                                                                                                    <div
                                                                                                        class="block-component">
                                                                                                        <span
                                                                                                            style="font-size: 15px;"><span
                                                                                                                data-custom-class="body_text"></span></span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        style="line-height: 1.5;">
                                                                                                        <div
                                                                                                            class="block-component">
                                                                                                            <span
                                                                                                                style="font-size: 15px;"><span
                                                                                                                    data-custom-class="body_text"></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <div
                                                                                                                class="block-component">
                                                                                                                <span
                                                                                                                    style="font-size: 15px;"><span
                                                                                                                        data-custom-class="body_text"></span></span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style="line-height: 1.5;">
                                                                                                                <div
                                                                                                                    class="block-component">
                                                                                                                    <span
                                                                                                                        style="font-size: 15px;"><span
                                                                                                                            data-custom-class="body_text"></span></span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style="line-height: 1.5;">
                                                                                                                    <div
                                                                                                                        class="block-component">
                                                                                                                        <span
                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                data-custom-class="body_text"></span></span>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        style="line-height: 1.5;">
                                                                                                                        <div
                                                                                                                            class="block-component">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                    data-custom-class="body_text"></span></span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <div
                                                                                                                                class="block-component">
                                                                                                                                <span
                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                        data-custom-class="body_text"></span></span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <div
                                                                                                                                    class="block-component">
                                                                                                                                    <span
                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                            data-custom-class="body_text"></span></span>
                                                                                                                                </div>
                                                                                                                                <div
                                                                                                                                    class="block-component">
                                                                                                                                    <span
                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                            data-custom-class="body_text"></span></span>
                                                                                                                                </div>
                                                                                                                                <div
                                                                                                                                    class="block-component">
                                                                                                                                    <span
                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                            data-custom-class="body_text"></span></span>
                                                                                                                                </div>
                                                                                                                                <div
                                                                                                                                    class="block-component">
                                                                                                                                    <span
                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                            data-custom-class="body_text"></span></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <br>
                                                                                                                            </div>
                                                                                                                            <div id="whoshare"
                                                                                                                                style="line-height: 1.5;"
                                                                                                                                class="_mPS2id-t">
                                                                                                                                <span
                                                                                                                                    style="color: rgb(127, 127, 127);"><span
                                                                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    id="control"
                                                                                                                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                            data-custom-class="heading_1">3.
                                                                                                                                                            WHEN
                                                                                                                                                            AND
                                                                                                                                                            WITH
                                                                                                                                                            WHOM
                                                                                                                                                            DO
                                                                                                                                                            WE
                                                                                                                                                            SHARE
                                                                                                                                                            YOUR
                                                                                                                                                            PERSONAL
                                                                                                                                                            INFORMATION?</span></strong></span></span></span></span></span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <br>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                            data-custom-class="body_text"><strong><em>In
                                                                                                                                                    Short:</em></strong><em>&nbsp;We
                                                                                                                                                may
                                                                                                                                                share
                                                                                                                                                information
                                                                                                                                                in
                                                                                                                                                specific
                                                                                                                                                situations
                                                                                                                                                described
                                                                                                                                                in
                                                                                                                                                this
                                                                                                                                                section
                                                                                                                                                and/or
                                                                                                                                                with
                                                                                                                                                the
                                                                                                                                                following

                                                                                                                                                third
                                                                                                                                                parties.
                                                                                                                                            </em></span></span></span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                            data-custom-class="body_text">
                                                                                                                                            <div
                                                                                                                                                class="block-component">
                                                                                                                                            </div>
                                                                                                                                        </span></span></span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <br>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <span
                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                        data-custom-class="body_text">We

                                                                                                                                        may
                                                                                                                                        need
                                                                                                                                        to
                                                                                                                                        share
                                                                                                                                        your
                                                                                                                                        personal
                                                                                                                                        information
                                                                                                                                        in
                                                                                                                                        the
                                                                                                                                        following
                                                                                                                                        situations:
                                                                                                                                    </span></span>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                    <span
                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                            data-custom-class="body_text"><strong>Business
                                                                                                                                                Transfers.</strong>
                                                                                                                                            We
                                                                                                                                            may
                                                                                                                                            share
                                                                                                                                            or
                                                                                                                                            transfer
                                                                                                                                            your
                                                                                                                                            information
                                                                                                                                            in
                                                                                                                                            connection
                                                                                                                                            with,
                                                                                                                                            or
                                                                                                                                            during
                                                                                                                                            negotiations
                                                                                                                                            of,
                                                                                                                                            any
                                                                                                                                            merger,
                                                                                                                                            sale
                                                                                                                                            of
                                                                                                                                            company
                                                                                                                                            assets,
                                                                                                                                            financing,
                                                                                                                                            or
                                                                                                                                            acquisition
                                                                                                                                            of
                                                                                                                                            all
                                                                                                                                            or
                                                                                                                                            a
                                                                                                                                            portion
                                                                                                                                            of
                                                                                                                                            our
                                                                                                                                            business
                                                                                                                                            to
                                                                                                                                            another
                                                                                                                                            company.</span></span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <span
                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                        data-custom-class="body_text">
                                                                                                                                        <div
                                                                                                                                            class="block-component">
                                                                                                                                        </div>
                                                                                                                                    </span></span>
                                                                                                                                <div
                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                    <span
                                                                                                                                        style="font-size: 15px;">
                                                                                                                                        <div
                                                                                                                                            class="block-component">
                                                                                                                                            <span
                                                                                                                                                data-custom-class="body_text"></span>
                                                                                                                                        </div>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                                <ul>
                                                                                                                                    <li
                                                                                                                                        style="line-height: 1.5;">
                                                                                                                                        <span
                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                data-custom-class="body_text"><strong>Affiliates.&nbsp;</strong>We
                                                                                                                                                may
                                                                                                                                                share
                                                                                                                                                your
                                                                                                                                                information
                                                                                                                                                with
                                                                                                                                                our
                                                                                                                                                affiliates,
                                                                                                                                                in
                                                                                                                                                which
                                                                                                                                                case
                                                                                                                                                we
                                                                                                                                                will
                                                                                                                                                require
                                                                                                                                                those
                                                                                                                                                affiliates
                                                                                                                                                to

                                                                                                                                                honor

                                                                                                                                                this
                                                                                                                                                privacy
                                                                                                                                                notice.
                                                                                                                                                Affiliates
                                                                                                                                                include
                                                                                                                                                our
                                                                                                                                                parent
                                                                                                                                                company
                                                                                                                                                and
                                                                                                                                                any
                                                                                                                                                subsidiaries,
                                                                                                                                                joint
                                                                                                                                                venture
                                                                                                                                                partners,
                                                                                                                                                or
                                                                                                                                                other
                                                                                                                                                companies
                                                                                                                                                that
                                                                                                                                                we
                                                                                                                                                control
                                                                                                                                                or
                                                                                                                                                that
                                                                                                                                                are
                                                                                                                                                under
                                                                                                                                                common
                                                                                                                                                control
                                                                                                                                                with
                                                                                                                                                us.
                                                                                                                                            </span>
                                                                                                                                            <div
                                                                                                                                                class="statement-end-if-in-editor">
                                                                                                                                                <span
                                                                                                                                                    data-custom-class="body_text"></span>
                                                                                                                                            </div>
                                                                                                                                        </span>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                                <div
                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                    <div
                                                                                                                                        class="block-component">
                                                                                                                                        <span
                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                data-custom-class="body_text"></span></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <ul>
                                                                                                                                    <li
                                                                                                                                        style="line-height: 1.5;">
                                                                                                                                        <span
                                                                                                                                            data-custom-class="body_text"><span
                                                                                                                                                style="font-size: 15px;"><strong>Business
                                                                                                                                                    Partners.</strong>
                                                                                                                                                We
                                                                                                                                                may
                                                                                                                                                share
                                                                                                                                                your
                                                                                                                                                information
                                                                                                                                                with
                                                                                                                                                our
                                                                                                                                                business
                                                                                                                                                partners
                                                                                                                                                to
                                                                                                                                                offer
                                                                                                                                                you
                                                                                                                                                certain
                                                                                                                                                products,
                                                                                                                                                services,
                                                                                                                                                or
                                                                                                                                                promotions.</span></span>
                                                                                                                                        <div
                                                                                                                                            class="statement-end-if-in-editor">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                    data-custom-class="body_text"></span></span>
                                                                                                                                        </div>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                                <div
                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                    <div
                                                                                                                                        class="block-component">
                                                                                                                                        <span
                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                data-custom-class="body_text"></span></span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style="line-height: 1.5;">
                                                                                                                                        <div
                                                                                                                                            class="block-component">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                    data-custom-class="body_text"></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <div
                                                                                                                                                class="block-component">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"></span></span>
                                                                                                                                            </div>
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89);">
                                                                                                                                                                <div
                                                                                                                                                                    class="block-component">
                                                                                                                                                                    <span
                                                                                                                                                                        data-custom-class="heading_1"></span>
                                                                                                                                                                </div>
                                                                                                                                                            </span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="cookies"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">4.
                                                                                                                                                                        DO
                                                                                                                                                                        WE
                                                                                                                                                                        USE
                                                                                                                                                                        COOKIES
                                                                                                                                                                        AND
                                                                                                                                                                        OTHER
                                                                                                                                                                        TRACKING
                                                                                                                                                                        TECHNOLOGIES?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                                                Short:</em></strong><em>&nbsp;We
                                                                                                                                                            may
                                                                                                                                                            use
                                                                                                                                                            cookies
                                                                                                                                                            and
                                                                                                                                                            other
                                                                                                                                                            tracking
                                                                                                                                                            technologies
                                                                                                                                                            to
                                                                                                                                                            collect
                                                                                                                                                            and
                                                                                                                                                            store
                                                                                                                                                            your
                                                                                                                                                            information.</em></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">We
                                                                                                                                                        may
                                                                                                                                                        use
                                                                                                                                                        cookies
                                                                                                                                                        and
                                                                                                                                                        similar
                                                                                                                                                        tracking
                                                                                                                                                        technologies
                                                                                                                                                        (like
                                                                                                                                                        web
                                                                                                                                                        beacons
                                                                                                                                                        and
                                                                                                                                                        pixels)
                                                                                                                                                        to
                                                                                                                                                        access
                                                                                                                                                        or
                                                                                                                                                        store
                                                                                                                                                        information.
                                                                                                                                                        Specific
                                                                                                                                                        information
                                                                                                                                                        about
                                                                                                                                                        how
                                                                                                                                                        we
                                                                                                                                                        use
                                                                                                                                                        such
                                                                                                                                                        technologies
                                                                                                                                                        and
                                                                                                                                                        how
                                                                                                                                                        you
                                                                                                                                                        can
                                                                                                                                                        refuse
                                                                                                                                                        certain
                                                                                                                                                        cookies
                                                                                                                                                        is
                                                                                                                                                        set
                                                                                                                                                        out
                                                                                                                                                        in
                                                                                                                                                        our
                                                                                                                                                        Cookie
                                                                                                                                                        Notice<span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                data-custom-class="body_text">

                                                                                                                                                                .
                                                                                                                                                            </span><span
                                                                                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                    data-custom-class="body_text">
                                                                                                                                                                                    <div
                                                                                                                                                                                        class="statement-end-if-in-editor">
                                                                                                                                                                                    </div>
                                                                                                                                                                                </span></span></span></span></span></span></span></span><span
                                                                                                                                                        data-custom-class="body_text"><span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                        data-custom-class="body_text">
                                                                                                                                                                                        <div
                                                                                                                                                                                            class="block-component">
                                                                                                                                                                                        </div>
                                                                                                                                                                                    </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="sociallogins"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">5.
                                                                                                                                                                        HOW
                                                                                                                                                                        DO
                                                                                                                                                                        WE
                                                                                                                                                                        HANDLE
                                                                                                                                                                        YOUR
                                                                                                                                                                        SOCIAL
                                                                                                                                                                        LOGINS?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                                                Short:&nbsp;</em></strong><em>If
                                                                                                                                                            you
                                                                                                                                                            choose
                                                                                                                                                            to
                                                                                                                                                            register
                                                                                                                                                            or
                                                                                                                                                            log
                                                                                                                                                            in
                                                                                                                                                            to
                                                                                                                                                            our
                                                                                                                                                            Services
                                                                                                                                                            using
                                                                                                                                                            a
                                                                                                                                                            social
                                                                                                                                                            media
                                                                                                                                                            account,
                                                                                                                                                            we
                                                                                                                                                            may
                                                                                                                                                            have
                                                                                                                                                            access
                                                                                                                                                            to
                                                                                                                                                            certain
                                                                                                                                                            information
                                                                                                                                                            about
                                                                                                                                                            you.</em></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">Our
                                                                                                                                                        Services
                                                                                                                                                        offer
                                                                                                                                                        you
                                                                                                                                                        the
                                                                                                                                                        ability
                                                                                                                                                        to
                                                                                                                                                        register
                                                                                                                                                        and
                                                                                                                                                        log
                                                                                                                                                        in
                                                                                                                                                        using
                                                                                                                                                        your
                                                                                                                                                        third-party
                                                                                                                                                        social
                                                                                                                                                        media
                                                                                                                                                        account
                                                                                                                                                        details
                                                                                                                                                        (like
                                                                                                                                                        your
                                                                                                                                                        Facebook
                                                                                                                                                        or
                                                                                                                                                        Twitter
                                                                                                                                                        logins).
                                                                                                                                                        Where
                                                                                                                                                        you
                                                                                                                                                        choose
                                                                                                                                                        to
                                                                                                                                                        do
                                                                                                                                                        this,
                                                                                                                                                        we
                                                                                                                                                        will
                                                                                                                                                        receive
                                                                                                                                                        certain
                                                                                                                                                        profile
                                                                                                                                                        information
                                                                                                                                                        about
                                                                                                                                                        you
                                                                                                                                                        from
                                                                                                                                                        your
                                                                                                                                                        social
                                                                                                                                                        media
                                                                                                                                                        provider.
                                                                                                                                                        The
                                                                                                                                                        profile
                                                                                                                                                        information
                                                                                                                                                        we
                                                                                                                                                        receive
                                                                                                                                                        may
                                                                                                                                                        vary
                                                                                                                                                        depending
                                                                                                                                                        on
                                                                                                                                                        the
                                                                                                                                                        social
                                                                                                                                                        media
                                                                                                                                                        provider
                                                                                                                                                        concerned,
                                                                                                                                                        but
                                                                                                                                                        will
                                                                                                                                                        often
                                                                                                                                                        include
                                                                                                                                                        your
                                                                                                                                                        name,
                                                                                                                                                        email
                                                                                                                                                        address,
                                                                                                                                                        friends
                                                                                                                                                        list,
                                                                                                                                                        and
                                                                                                                                                        profile
                                                                                                                                                        picture,
                                                                                                                                                        as
                                                                                                                                                        well
                                                                                                                                                        as
                                                                                                                                                        other
                                                                                                                                                        information
                                                                                                                                                        you
                                                                                                                                                        choose
                                                                                                                                                        to
                                                                                                                                                        make
                                                                                                                                                        public
                                                                                                                                                        on
                                                                                                                                                        such
                                                                                                                                                        a
                                                                                                                                                        social
                                                                                                                                                        media
                                                                                                                                                        platform.
                                                                                                                                                        <span
                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                    data-custom-class="body_text">
                                                                                                                                                                    <div
                                                                                                                                                                        class="block-component">
                                                                                                                                                                    </div>
                                                                                                                                                                </span></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">We
                                                                                                                                                        will
                                                                                                                                                        use
                                                                                                                                                        the
                                                                                                                                                        information
                                                                                                                                                        we
                                                                                                                                                        receive
                                                                                                                                                        only
                                                                                                                                                        for
                                                                                                                                                        the
                                                                                                                                                        purposes
                                                                                                                                                        that
                                                                                                                                                        are
                                                                                                                                                        described
                                                                                                                                                        in
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        or
                                                                                                                                                        that
                                                                                                                                                        are
                                                                                                                                                        otherwise
                                                                                                                                                        made
                                                                                                                                                        clear
                                                                                                                                                        to
                                                                                                                                                        you
                                                                                                                                                        on
                                                                                                                                                        the
                                                                                                                                                        relevant
                                                                                                                                                        Services.
                                                                                                                                                        Please
                                                                                                                                                        note
                                                                                                                                                        that
                                                                                                                                                        we
                                                                                                                                                        do
                                                                                                                                                        not
                                                                                                                                                        control,
                                                                                                                                                        and
                                                                                                                                                        are
                                                                                                                                                        not
                                                                                                                                                        responsible
                                                                                                                                                        for,
                                                                                                                                                        other
                                                                                                                                                        uses
                                                                                                                                                        of
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        by
                                                                                                                                                        your
                                                                                                                                                        third-party
                                                                                                                                                        social
                                                                                                                                                        media
                                                                                                                                                        provider.
                                                                                                                                                        We
                                                                                                                                                        recommend
                                                                                                                                                        that
                                                                                                                                                        you
                                                                                                                                                        review
                                                                                                                                                        their
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        to
                                                                                                                                                        understand
                                                                                                                                                        how
                                                                                                                                                        they
                                                                                                                                                        collect,
                                                                                                                                                        use,
                                                                                                                                                        and
                                                                                                                                                        share
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information,
                                                                                                                                                        and
                                                                                                                                                        how
                                                                                                                                                        you
                                                                                                                                                        can
                                                                                                                                                        set
                                                                                                                                                        your
                                                                                                                                                        privacy
                                                                                                                                                        preferences
                                                                                                                                                        on
                                                                                                                                                        their
                                                                                                                                                        sites
                                                                                                                                                        and
                                                                                                                                                        apps.<span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                            data-custom-class="body_text">
                                                                                                                                                                                            <div
                                                                                                                                                                                                class="statement-end-if-in-editor">
                                                                                                                                                                                            </div>
                                                                                                                                                                                        </span>
                                                                                                                                                                                        <div
                                                                                                                                                                                            class="block-component">
                                                                                                                                                                                            <span
                                                                                                                                                                                                data-custom-class="body_text">
                                                                                                                                                                                                <div
                                                                                                                                                                                                    class="block-component">
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </div>
                                                                                                                                                                                    </span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="intltransfers"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">6.
                                                                                                                                                                        IS
                                                                                                                                                                        YOUR
                                                                                                                                                                        INFORMATION
                                                                                                                                                                        TRANSFERRED
                                                                                                                                                                        INTERNATIONALLY?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                                                Short:&nbsp;</em></strong><em>We
                                                                                                                                                            may
                                                                                                                                                            transfer,
                                                                                                                                                            store,
                                                                                                                                                            and
                                                                                                                                                            process
                                                                                                                                                            your
                                                                                                                                                            information
                                                                                                                                                            in
                                                                                                                                                            countries
                                                                                                                                                            other
                                                                                                                                                            than
                                                                                                                                                            your
                                                                                                                                                            own.</em></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div data-custom-class="body_text"
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">Our
                                                                                                                                                        servers
                                                                                                                                                        are
                                                                                                                                                        located
                                                                                                                                                        in
                                                                                                                                                        India.
                                                                                                                                                        If
                                                                                                                                                        you
                                                                                                                                                        are
                                                                                                                                                        accessing
                                                                                                                                                        our
                                                                                                                                                        Services
                                                                                                                                                        from
                                                                                                                                                        outside
                                                                                                                                                        please
                                                                                                                                                        be
                                                                                                                                                        aware
                                                                                                                                                        that
                                                                                                                                                        your
                                                                                                                                                        information
                                                                                                                                                        may
                                                                                                                                                        be
                                                                                                                                                        transferred
                                                                                                                                                        to,
                                                                                                                                                        stored,
                                                                                                                                                        and
                                                                                                                                                        processed
                                                                                                                                                        by
                                                                                                                                                        us
                                                                                                                                                        in
                                                                                                                                                        our
                                                                                                                                                        facilities
                                                                                                                                                        and
                                                                                                                                                        by
                                                                                                                                                        those
                                                                                                                                                        third
                                                                                                                                                        parties
                                                                                                                                                        with
                                                                                                                                                        whom
                                                                                                                                                        we
                                                                                                                                                        may
                                                                                                                                                        share
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        (see
                                                                                                                                                        "

                                                                                                                                                        <a data-custom-class="link"
                                                                                                                                                            href="#whoshare"
                                                                                                                                                            class="__mPS2id _mPS2id-h">
                                                                                                                                                            <span
                                                                                                                                                                style="font-size: 15px;">
                                                                                                                                                                <span
                                                                                                                                                                    style="color: rgb(89, 89, 89);">
                                                                                                                                                                    WHEN
                                                                                                                                                                    AND
                                                                                                                                                                    WITH
                                                                                                                                                                    WHOM
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    SHARE
                                                                                                                                                                    YOUR
                                                                                                                                                                    PERSONAL
                                                                                                                                                                    INFORMATION?
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </a>
                                                                                                                                                        <span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                                                                                                            <span
                                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                                                                                                                <span
                                                                                                                                                                    data-custom-class="body_text">
                                                                                                                                                                    ”
                                                                                                                                                                    above),
                                                                                                                                                                    in
                                                                                                                                                                </span>
                                                                                                                                                            </span>&nbsp;
                                                                                                                                                        </span>

                                                                                                                                                        <span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                                                                                                            <span
                                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                                                                                                                <span
                                                                                                                                                                    data-custom-class="body_text">&nbsp;and
                                                                                                                                                                    other
                                                                                                                                                                    countries.
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">If
                                                                                                                                                        you
                                                                                                                                                        are
                                                                                                                                                        a
                                                                                                                                                        resident
                                                                                                                                                        in
                                                                                                                                                        the
                                                                                                                                                        European
                                                                                                                                                        Economic
                                                                                                                                                        Area
                                                                                                                                                        (EEA),
                                                                                                                                                        United
                                                                                                                                                        Kingdom
                                                                                                                                                        (UK),
                                                                                                                                                        or
                                                                                                                                                        Switzerland,
                                                                                                                                                        then
                                                                                                                                                        these
                                                                                                                                                        countries
                                                                                                                                                        may
                                                                                                                                                        not
                                                                                                                                                        necessarily
                                                                                                                                                        have
                                                                                                                                                        data
                                                                                                                                                        protection
                                                                                                                                                        laws
                                                                                                                                                        or
                                                                                                                                                        other
                                                                                                                                                        similar
                                                                                                                                                        laws
                                                                                                                                                        as
                                                                                                                                                        comprehensive
                                                                                                                                                        as
                                                                                                                                                        those
                                                                                                                                                        in
                                                                                                                                                        your
                                                                                                                                                        country.
                                                                                                                                                        However,
                                                                                                                                                        we
                                                                                                                                                        will
                                                                                                                                                        take
                                                                                                                                                        all
                                                                                                                                                        necessary
                                                                                                                                                        measures
                                                                                                                                                        to
                                                                                                                                                        protect
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        in
                                                                                                                                                        accordance
                                                                                                                                                        with
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        and
                                                                                                                                                        applicable
                                                                                                                                                        law.<span
                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                    data-custom-class="body_text">
                                                                                                                                                                    <div
                                                                                                                                                                        class="block-component">
                                                                                                                                                                    </div>
                                                                                                                                                                </span></span></span></span></span></span><span
                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">

                                                                                                                                                    </span></span></span><span
                                                                                                                                                data-custom-class="body_text"><span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">

                                                                                                                                                        </span></span></span></span><span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                                                data-custom-class="body_text">
                                                                                                                                                                                                <div
                                                                                                                                                                                                    class="statement-end-if-in-editor">
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </span></span></span></span></span></span></span></span></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="inforetain"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">7.
                                                                                                                                                                        HOW
                                                                                                                                                                        LONG
                                                                                                                                                                        DO
                                                                                                                                                                        WE
                                                                                                                                                                        KEEP
                                                                                                                                                                        YOUR
                                                                                                                                                                        INFORMATION?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                                                Short:&nbsp;</em></strong><em>We
                                                                                                                                                            keep
                                                                                                                                                            your
                                                                                                                                                            information
                                                                                                                                                            for
                                                                                                                                                            as
                                                                                                                                                            long
                                                                                                                                                            as
                                                                                                                                                            necessary
                                                                                                                                                            to

                                                                                                                                                            fulfill

                                                                                                                                                            the
                                                                                                                                                            purposes
                                                                                                                                                            outlined
                                                                                                                                                            in
                                                                                                                                                            this
                                                                                                                                                            privacy
                                                                                                                                                            notice
                                                                                                                                                            unless
                                                                                                                                                            otherwise
                                                                                                                                                            required
                                                                                                                                                            by
                                                                                                                                                            law.
                                                                                                                                                        </em></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">We
                                                                                                                                                        will
                                                                                                                                                        only
                                                                                                                                                        keep
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        for
                                                                                                                                                        as
                                                                                                                                                        long
                                                                                                                                                        as
                                                                                                                                                        it
                                                                                                                                                        is
                                                                                                                                                        necessary
                                                                                                                                                        for
                                                                                                                                                        the
                                                                                                                                                        purposes
                                                                                                                                                        set
                                                                                                                                                        out
                                                                                                                                                        in
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice,
                                                                                                                                                        unless
                                                                                                                                                        a
                                                                                                                                                        longer
                                                                                                                                                        retention
                                                                                                                                                        period
                                                                                                                                                        is
                                                                                                                                                        required
                                                                                                                                                        or
                                                                                                                                                        permitted
                                                                                                                                                        by
                                                                                                                                                        law
                                                                                                                                                        (such
                                                                                                                                                        as
                                                                                                                                                        tax,
                                                                                                                                                        accounting,
                                                                                                                                                        or
                                                                                                                                                        other
                                                                                                                                                        legal
                                                                                                                                                        requirements).

                                                                                                                                                    </span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">When
                                                                                                                                                        we
                                                                                                                                                        have
                                                                                                                                                        no
                                                                                                                                                        ongoing
                                                                                                                                                        legitimate
                                                                                                                                                        business
                                                                                                                                                        need
                                                                                                                                                        to
                                                                                                                                                        process
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information,
                                                                                                                                                        we
                                                                                                                                                        will
                                                                                                                                                        either
                                                                                                                                                        delete
                                                                                                                                                        or

                                                                                                                                                        anonymize

                                                                                                                                                        such
                                                                                                                                                        information,
                                                                                                                                                        or,
                                                                                                                                                        if
                                                                                                                                                        this
                                                                                                                                                        is
                                                                                                                                                        not
                                                                                                                                                        possible
                                                                                                                                                        (for
                                                                                                                                                        example,
                                                                                                                                                        because
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        has
                                                                                                                                                        been
                                                                                                                                                        stored
                                                                                                                                                        in
                                                                                                                                                        backup
                                                                                                                                                        archives),
                                                                                                                                                        then
                                                                                                                                                        we
                                                                                                                                                        will
                                                                                                                                                        securely
                                                                                                                                                        store
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        and
                                                                                                                                                        isolate
                                                                                                                                                        it
                                                                                                                                                        from
                                                                                                                                                        any
                                                                                                                                                        further
                                                                                                                                                        processing
                                                                                                                                                        until
                                                                                                                                                        deletion
                                                                                                                                                        is
                                                                                                                                                        possible.<span
                                                                                                                                                            style="color: rgb(89, 89, 89);">

                                                                                                                                                        </span><span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                data-custom-class="body_text">

                                                                                                                                                            </span></span>
                                                                                                                                                    </span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="infominors"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">8.
                                                                                                                                                                        DO
                                                                                                                                                                        WE
                                                                                                                                                                        COLLECT
                                                                                                                                                                        INFORMATION
                                                                                                                                                                        FROM
                                                                                                                                                                        MINORS?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                                                Short:</em></strong><em>&nbsp;We
                                                                                                                                                            do
                                                                                                                                                            not
                                                                                                                                                            knowingly
                                                                                                                                                            collect
                                                                                                                                                            data
                                                                                                                                                            from
                                                                                                                                                            or
                                                                                                                                                            market
                                                                                                                                                            to

                                                                                                                                                            children
                                                                                                                                                            under
                                                                                                                                                            18
                                                                                                                                                            years
                                                                                                                                                            of
                                                                                                                                                            age
                                                                                                                                                            .
                                                                                                                                                        </em>

                                                                                                                                                    </span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">We
                                                                                                                                                        do
                                                                                                                                                        not
                                                                                                                                                        knowingly
                                                                                                                                                        solicit
                                                                                                                                                        data
                                                                                                                                                        from
                                                                                                                                                        or
                                                                                                                                                        market
                                                                                                                                                        to
                                                                                                                                                        children
                                                                                                                                                        under
                                                                                                                                                        18
                                                                                                                                                        years
                                                                                                                                                        of
                                                                                                                                                        age.
                                                                                                                                                        By
                                                                                                                                                        using
                                                                                                                                                        the
                                                                                                                                                        Services,
                                                                                                                                                        you
                                                                                                                                                        represent
                                                                                                                                                        that
                                                                                                                                                        you
                                                                                                                                                        are
                                                                                                                                                        at
                                                                                                                                                        least
                                                                                                                                                        18
                                                                                                                                                        or
                                                                                                                                                        that
                                                                                                                                                        you
                                                                                                                                                        are
                                                                                                                                                        the
                                                                                                                                                        parent
                                                                                                                                                        or
                                                                                                                                                        guardian
                                                                                                                                                        of
                                                                                                                                                        such
                                                                                                                                                        a
                                                                                                                                                        minor
                                                                                                                                                        and
                                                                                                                                                        consent
                                                                                                                                                        to
                                                                                                                                                        such
                                                                                                                                                        minor
                                                                                                                                                        dependent’s
                                                                                                                                                        use
                                                                                                                                                        of
                                                                                                                                                        the
                                                                                                                                                        Services.
                                                                                                                                                        If
                                                                                                                                                        we
                                                                                                                                                        learn
                                                                                                                                                        that
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        from
                                                                                                                                                        users
                                                                                                                                                        less
                                                                                                                                                        than
                                                                                                                                                        18
                                                                                                                                                        years
                                                                                                                                                        of
                                                                                                                                                        age
                                                                                                                                                        has
                                                                                                                                                        been
                                                                                                                                                        collected,
                                                                                                                                                        we
                                                                                                                                                        will
                                                                                                                                                        deactivate
                                                                                                                                                        the
                                                                                                                                                        account
                                                                                                                                                        and
                                                                                                                                                        take
                                                                                                                                                        reasonable
                                                                                                                                                        measures
                                                                                                                                                        to
                                                                                                                                                        promptly
                                                                                                                                                        delete
                                                                                                                                                        such
                                                                                                                                                        data
                                                                                                                                                        from
                                                                                                                                                        our
                                                                                                                                                        records.
                                                                                                                                                        If
                                                                                                                                                        you
                                                                                                                                                        become
                                                                                                                                                        aware
                                                                                                                                                        of
                                                                                                                                                        any
                                                                                                                                                        data
                                                                                                                                                        we
                                                                                                                                                        may
                                                                                                                                                        have
                                                                                                                                                        collected
                                                                                                                                                        from
                                                                                                                                                        children
                                                                                                                                                        under
                                                                                                                                                        age
                                                                                                                                                        18,
                                                                                                                                                        please
                                                                                                                                                        contact
                                                                                                                                                        us
                                                                                                                                                        at
                                                                                                                                                        <span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                data-custom-class="body_text">

                                                                                                                                                                <div
                                                                                                                                                                    class="question">
                                                                                                                                                                    admin@bilwg.com.
                                                                                                                                                                </div>

                                                                                                                                                            </span>
                                                                                                                                                        </span></span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="privacyrights"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">9.
                                                                                                                                                                        WHAT
                                                                                                                                                                        ARE
                                                                                                                                                                        YOUR
                                                                                                                                                                        PRIVACY
                                                                                                                                                                        RIGHTS?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                                                Short:</em></strong><em>&nbsp;<span
                                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                                        data-custom-class="body_text"><em>
                                                                                                                                                                            <div
                                                                                                                                                                                class="block-component">
                                                                                                                                                                            </div>
                                                                                                                                                                        </em></span></span>&nbsp;</span>You
                                                                                                                                                            may
                                                                                                                                                            review,
                                                                                                                                                            change,
                                                                                                                                                            or
                                                                                                                                                            terminate
                                                                                                                                                            your
                                                                                                                                                            account
                                                                                                                                                            at
                                                                                                                                                            any
                                                                                                                                                            time.</em><span
                                                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                style="font-size: 15px;">
                                                                                                                                                                <div
                                                                                                                                                                    class="block-component">
                                                                                                                                                                    <div
                                                                                                                                                                        class="block-component">
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            </span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="withdrawconsent"
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><strong><u>Withdrawing
                                                                                                                                                                your
                                                                                                                                                                consent:</u></strong>
                                                                                                                                                        If
                                                                                                                                                        we
                                                                                                                                                        are
                                                                                                                                                        relying
                                                                                                                                                        on
                                                                                                                                                        your
                                                                                                                                                        consent
                                                                                                                                                        to
                                                                                                                                                        process
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information,

                                                                                                                                                        which
                                                                                                                                                        may
                                                                                                                                                        be
                                                                                                                                                        express
                                                                                                                                                        and/or
                                                                                                                                                        implied
                                                                                                                                                        consent
                                                                                                                                                        depending
                                                                                                                                                        on
                                                                                                                                                        the
                                                                                                                                                        applicable
                                                                                                                                                        law,

                                                                                                                                                        you
                                                                                                                                                        have
                                                                                                                                                        the
                                                                                                                                                        right
                                                                                                                                                        to
                                                                                                                                                        withdraw
                                                                                                                                                        your
                                                                                                                                                        consent
                                                                                                                                                        at
                                                                                                                                                        any
                                                                                                                                                        time.
                                                                                                                                                        You
                                                                                                                                                        can
                                                                                                                                                        withdraw
                                                                                                                                                        your
                                                                                                                                                        consent
                                                                                                                                                        at
                                                                                                                                                        any
                                                                                                                                                        time
                                                                                                                                                        by
                                                                                                                                                        contacting
                                                                                                                                                        us
                                                                                                                                                        by
                                                                                                                                                        using
                                                                                                                                                        the
                                                                                                                                                        contact
                                                                                                                                                        details
                                                                                                                                                        provided
                                                                                                                                                        in
                                                                                                                                                        the
                                                                                                                                                        section

                                                                                                                                                        ”

                                                                                                                                                    </span></span></span><a
                                                                                                                                                data-custom-class="link"
                                                                                                                                                href="#contact"
                                                                                                                                                class="__mPS2id _mPS2id-h"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">HOW
                                                                                                                                                            CAN
                                                                                                                                                            YOU
                                                                                                                                                            CONTACT
                                                                                                                                                            US
                                                                                                                                                            ABOUT
                                                                                                                                                            THIS
                                                                                                                                                            NOTICE?</span></span></span></a><span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">
                                                                                                                                                        ”below.
                                                                                                                                                    </span></span></span>
                                                                                                                                        </div>

                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px;">
                                                                                                                                                <span
                                                                                                                                                    data-custom-class="body_text">However,
                                                                                                                                                    please
                                                                                                                                                    note
                                                                                                                                                    that
                                                                                                                                                    this
                                                                                                                                                    will
                                                                                                                                                    not
                                                                                                                                                    affect
                                                                                                                                                    the
                                                                                                                                                    lawfulness
                                                                                                                                                    of
                                                                                                                                                    the
                                                                                                                                                    processing
                                                                                                                                                    before
                                                                                                                                                    its
                                                                                                                                                    withdrawal
                                                                                                                                                    nor,

                                                                                                                                                    when
                                                                                                                                                    applicable
                                                                                                                                                    law
                                                                                                                                                    allows,

                                                                                                                                                    will
                                                                                                                                                    it
                                                                                                                                                    affect
                                                                                                                                                    the
                                                                                                                                                    processing
                                                                                                                                                    of
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    conducted
                                                                                                                                                    in
                                                                                                                                                    reliance
                                                                                                                                                    on
                                                                                                                                                    lawful
                                                                                                                                                    processing
                                                                                                                                                    grounds
                                                                                                                                                    other
                                                                                                                                                    than
                                                                                                                                                    consent.

                                                                                                                                                </span></span>
                                                                                                                                            <div
                                                                                                                                                class="block-component">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"></span></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                    data-custom-class="heading_2"><strong>Account
                                                                                                                                                        Information</strong></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                data-custom-class="body_text"><span
                                                                                                                                                    style="font-size: 15px;">If
                                                                                                                                                    you
                                                                                                                                                    would
                                                                                                                                                    at
                                                                                                                                                    any
                                                                                                                                                    time
                                                                                                                                                    like
                                                                                                                                                    to
                                                                                                                                                    review
                                                                                                                                                    or
                                                                                                                                                    change
                                                                                                                                                    the
                                                                                                                                                    information
                                                                                                                                                    in
                                                                                                                                                    your
                                                                                                                                                    account
                                                                                                                                                    or
                                                                                                                                                    terminate
                                                                                                                                                    your
                                                                                                                                                    account,
                                                                                                                                                    you
                                                                                                                                                    can:
                                                                                                                                                    <div
                                                                                                                                                        class="forloop-component">
                                                                                                                                                    </div>
                                                                                                                                                </span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                    data-custom-class="body_text">Upon
                                                                                                                                                    your
                                                                                                                                                    request
                                                                                                                                                    to
                                                                                                                                                    terminate
                                                                                                                                                    your
                                                                                                                                                    account,
                                                                                                                                                    we
                                                                                                                                                    will
                                                                                                                                                    deactivate
                                                                                                                                                    or
                                                                                                                                                    delete
                                                                                                                                                    your
                                                                                                                                                    account
                                                                                                                                                    and
                                                                                                                                                    information
                                                                                                                                                    from
                                                                                                                                                    our
                                                                                                                                                    active
                                                                                                                                                    databases.
                                                                                                                                                    However,
                                                                                                                                                    we
                                                                                                                                                    may
                                                                                                                                                    retain
                                                                                                                                                    some
                                                                                                                                                    information
                                                                                                                                                    in
                                                                                                                                                    our
                                                                                                                                                    files
                                                                                                                                                    to
                                                                                                                                                    prevent
                                                                                                                                                    fraud,
                                                                                                                                                    troubleshoot
                                                                                                                                                    problems,
                                                                                                                                                    assist
                                                                                                                                                    with
                                                                                                                                                    any
                                                                                                                                                    investigations,
                                                                                                                                                    enforce
                                                                                                                                                    our
                                                                                                                                                    legal
                                                                                                                                                    terms
                                                                                                                                                    and/or
                                                                                                                                                    comply
                                                                                                                                                    with
                                                                                                                                                    applicable
                                                                                                                                                    legal
                                                                                                                                                    requirements.</span></span>
                                                                                                                                            <div
                                                                                                                                                class="statement-end-if-in-editor">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"></span></span>
                                                                                                                                            </div>
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><span
                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                            data-custom-class="body_text"><span
                                                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                                                    style="color: rgb(89, 89, 89);">
                                                                                                                                                                                    <div
                                                                                                                                                                                        class="block-component">
                                                                                                                                                                                    </div>
                                                                                                                                                                                </span></span></span></span></span></span></span></span></span></span>
                                                                                                                                            <div
                                                                                                                                                class="block-component">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"></span></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="DNT"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">10.
                                                                                                                                                                        CONTROLS
                                                                                                                                                                        FOR
                                                                                                                                                                        DO-NOT-TRACK
                                                                                                                                                                        FEATURES</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">Most
                                                                                                                                                        web
                                                                                                                                                        browsers
                                                                                                                                                        and
                                                                                                                                                        some
                                                                                                                                                        mobile
                                                                                                                                                        operating
                                                                                                                                                        systems
                                                                                                                                                        and
                                                                                                                                                        mobile
                                                                                                                                                        applications
                                                                                                                                                        include
                                                                                                                                                        a
                                                                                                                                                        Do-Not-Track
                                                                                                                                                        (

                                                                                                                                                        “DNT”

                                                                                                                                                        )
                                                                                                                                                        feature
                                                                                                                                                        or
                                                                                                                                                        setting
                                                                                                                                                        you
                                                                                                                                                        can
                                                                                                                                                        activate
                                                                                                                                                        to
                                                                                                                                                        signal
                                                                                                                                                        your
                                                                                                                                                        privacy
                                                                                                                                                        preference
                                                                                                                                                        not
                                                                                                                                                        to
                                                                                                                                                        have
                                                                                                                                                        data
                                                                                                                                                        about
                                                                                                                                                        your
                                                                                                                                                        online
                                                                                                                                                        browsing
                                                                                                                                                        activities
                                                                                                                                                        monitored
                                                                                                                                                        and
                                                                                                                                                        collected.
                                                                                                                                                        At
                                                                                                                                                        this
                                                                                                                                                        stage
                                                                                                                                                        no
                                                                                                                                                        uniform
                                                                                                                                                        technology
                                                                                                                                                        standard
                                                                                                                                                        for

                                                                                                                                                        recognizing

                                                                                                                                                        and
                                                                                                                                                        implementing
                                                                                                                                                        DNT
                                                                                                                                                        signals
                                                                                                                                                        has
                                                                                                                                                        been

                                                                                                                                                        finalized

                                                                                                                                                        .
                                                                                                                                                        As
                                                                                                                                                        such,
                                                                                                                                                        we
                                                                                                                                                        do
                                                                                                                                                        not
                                                                                                                                                        currently
                                                                                                                                                        respond
                                                                                                                                                        to
                                                                                                                                                        DNT
                                                                                                                                                        browser
                                                                                                                                                        signals
                                                                                                                                                        or
                                                                                                                                                        any
                                                                                                                                                        other
                                                                                                                                                        mechanism
                                                                                                                                                        that
                                                                                                                                                        automatically
                                                                                                                                                        communicates
                                                                                                                                                        your
                                                                                                                                                        choice
                                                                                                                                                        not
                                                                                                                                                        to
                                                                                                                                                        be
                                                                                                                                                        tracked
                                                                                                                                                        online.
                                                                                                                                                        If
                                                                                                                                                        a
                                                                                                                                                        standard
                                                                                                                                                        for
                                                                                                                                                        online
                                                                                                                                                        tracking
                                                                                                                                                        is
                                                                                                                                                        adopted
                                                                                                                                                        that
                                                                                                                                                        we
                                                                                                                                                        must
                                                                                                                                                        follow
                                                                                                                                                        in
                                                                                                                                                        the
                                                                                                                                                        future,
                                                                                                                                                        we
                                                                                                                                                        will
                                                                                                                                                        inform
                                                                                                                                                        you
                                                                                                                                                        about
                                                                                                                                                        that
                                                                                                                                                        practice
                                                                                                                                                        in
                                                                                                                                                        a
                                                                                                                                                        revised
                                                                                                                                                        version
                                                                                                                                                        of
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice.
                                                                                                                                                    </span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="caresidents"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">11.
                                                                                                                                                                        DO
                                                                                                                                                                        CALIFORNIA
                                                                                                                                                                        RESIDENTS
                                                                                                                                                                        HAVE
                                                                                                                                                                        SPECIFIC
                                                                                                                                                                        PRIVACY
                                                                                                                                                                        RIGHTS?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                                                Short:&nbsp;</em></strong><em>Yes,
                                                                                                                                                            if
                                                                                                                                                            you
                                                                                                                                                            are
                                                                                                                                                            a
                                                                                                                                                            resident
                                                                                                                                                            of
                                                                                                                                                            California,
                                                                                                                                                            you
                                                                                                                                                            are
                                                                                                                                                            granted
                                                                                                                                                            specific
                                                                                                                                                            rights
                                                                                                                                                            regarding
                                                                                                                                                            access
                                                                                                                                                            to
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information.</em></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">California
                                                                                                                                                        Civil
                                                                                                                                                        Code
                                                                                                                                                        Section
                                                                                                                                                        1798.83,
                                                                                                                                                        also
                                                                                                                                                        known
                                                                                                                                                        as
                                                                                                                                                        the

                                                                                                                                                        “Shine
                                                                                                                                                        The
                                                                                                                                                        Light”

                                                                                                                                                        law,
                                                                                                                                                        permits
                                                                                                                                                        our
                                                                                                                                                        users
                                                                                                                                                        who
                                                                                                                                                        are
                                                                                                                                                        California
                                                                                                                                                        residents
                                                                                                                                                        to
                                                                                                                                                        request
                                                                                                                                                        and
                                                                                                                                                        obtain
                                                                                                                                                        from
                                                                                                                                                        us,
                                                                                                                                                        once
                                                                                                                                                        a
                                                                                                                                                        year
                                                                                                                                                        and
                                                                                                                                                        free
                                                                                                                                                        of
                                                                                                                                                        charge,
                                                                                                                                                        information
                                                                                                                                                        about
                                                                                                                                                        categories
                                                                                                                                                        of
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        (if
                                                                                                                                                        any)
                                                                                                                                                        we
                                                                                                                                                        disclosed
                                                                                                                                                        to
                                                                                                                                                        third
                                                                                                                                                        parties
                                                                                                                                                        for
                                                                                                                                                        direct
                                                                                                                                                        marketing
                                                                                                                                                        purposes
                                                                                                                                                        and
                                                                                                                                                        the
                                                                                                                                                        names
                                                                                                                                                        and
                                                                                                                                                        addresses
                                                                                                                                                        of
                                                                                                                                                        all
                                                                                                                                                        third
                                                                                                                                                        parties
                                                                                                                                                        with
                                                                                                                                                        which
                                                                                                                                                        we
                                                                                                                                                        shared
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        in
                                                                                                                                                        the
                                                                                                                                                        immediately
                                                                                                                                                        preceding
                                                                                                                                                        calendar
                                                                                                                                                        year.
                                                                                                                                                        If
                                                                                                                                                        you
                                                                                                                                                        are
                                                                                                                                                        a
                                                                                                                                                        California
                                                                                                                                                        resident
                                                                                                                                                        and
                                                                                                                                                        would
                                                                                                                                                        like
                                                                                                                                                        to
                                                                                                                                                        make
                                                                                                                                                        such
                                                                                                                                                        a
                                                                                                                                                        request,
                                                                                                                                                        please
                                                                                                                                                        submit
                                                                                                                                                        your
                                                                                                                                                        request
                                                                                                                                                        in
                                                                                                                                                        writing
                                                                                                                                                        to
                                                                                                                                                        us
                                                                                                                                                        using
                                                                                                                                                        the
                                                                                                                                                        contact
                                                                                                                                                        information
                                                                                                                                                        provided
                                                                                                                                                        below.
                                                                                                                                                    </span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">If
                                                                                                                                                        you
                                                                                                                                                        are
                                                                                                                                                        under
                                                                                                                                                        18
                                                                                                                                                        years
                                                                                                                                                        of
                                                                                                                                                        age,
                                                                                                                                                        reside
                                                                                                                                                        in
                                                                                                                                                        California,
                                                                                                                                                        and
                                                                                                                                                        have
                                                                                                                                                        a
                                                                                                                                                        registered
                                                                                                                                                        account
                                                                                                                                                        with
                                                                                                                                                        Services,
                                                                                                                                                        you
                                                                                                                                                        have
                                                                                                                                                        the
                                                                                                                                                        right
                                                                                                                                                        to
                                                                                                                                                        request
                                                                                                                                                        removal
                                                                                                                                                        of
                                                                                                                                                        unwanted
                                                                                                                                                        data
                                                                                                                                                        that
                                                                                                                                                        you
                                                                                                                                                        publicly
                                                                                                                                                        post
                                                                                                                                                        on
                                                                                                                                                        the
                                                                                                                                                        Services.
                                                                                                                                                        To
                                                                                                                                                        request
                                                                                                                                                        removal
                                                                                                                                                        of
                                                                                                                                                        such
                                                                                                                                                        data,
                                                                                                                                                        please
                                                                                                                                                        contact
                                                                                                                                                        us
                                                                                                                                                        using
                                                                                                                                                        the
                                                                                                                                                        contact
                                                                                                                                                        information
                                                                                                                                                        provided
                                                                                                                                                        below
                                                                                                                                                        and
                                                                                                                                                        include
                                                                                                                                                        the
                                                                                                                                                        email
                                                                                                                                                        address
                                                                                                                                                        associated
                                                                                                                                                        with
                                                                                                                                                        your
                                                                                                                                                        account
                                                                                                                                                        and
                                                                                                                                                        a
                                                                                                                                                        statement
                                                                                                                                                        that
                                                                                                                                                        you
                                                                                                                                                        reside
                                                                                                                                                        in
                                                                                                                                                        California.
                                                                                                                                                        We
                                                                                                                                                        will
                                                                                                                                                        make
                                                                                                                                                        sure
                                                                                                                                                        the
                                                                                                                                                        data
                                                                                                                                                        is
                                                                                                                                                        not
                                                                                                                                                        publicly
                                                                                                                                                        displayed
                                                                                                                                                        on
                                                                                                                                                        the
                                                                                                                                                        Services,
                                                                                                                                                        but
                                                                                                                                                        please
                                                                                                                                                        be
                                                                                                                                                        aware
                                                                                                                                                        that
                                                                                                                                                        the
                                                                                                                                                        data
                                                                                                                                                        may
                                                                                                                                                        not
                                                                                                                                                        be
                                                                                                                                                        completely
                                                                                                                                                        or
                                                                                                                                                        comprehensively
                                                                                                                                                        removed
                                                                                                                                                        from
                                                                                                                                                        all
                                                                                                                                                        our
                                                                                                                                                        systems
                                                                                                                                                        (e.g.

                                                                                                                                                        ,

                                                                                                                                                        backups,
                                                                                                                                                        etc.).<span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                data-custom-class="body_text">

                                                                                                                                                            </span></span>
                                                                                                                                                    </span></span></span>
                                                                                                                                            <div
                                                                                                                                                class="block-component">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <div
                                                                                                                                                class="block-component">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="policyupdates"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">12.
                                                                                                                                                                        DO
                                                                                                                                                                        WE
                                                                                                                                                                        MAKE
                                                                                                                                                                        UPDATES
                                                                                                                                                                        TO
                                                                                                                                                                        THIS
                                                                                                                                                                        NOTICE?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><em><strong>In
                                                                                                                                                                Short:&nbsp;</strong>Yes,
                                                                                                                                                            we
                                                                                                                                                            will
                                                                                                                                                            update
                                                                                                                                                            this
                                                                                                                                                            notice
                                                                                                                                                            as
                                                                                                                                                            necessary
                                                                                                                                                            to
                                                                                                                                                            stay
                                                                                                                                                            compliant
                                                                                                                                                            with
                                                                                                                                                            relevant
                                                                                                                                                            laws.</em></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">We
                                                                                                                                                        may
                                                                                                                                                        update
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        from
                                                                                                                                                        time
                                                                                                                                                        to
                                                                                                                                                        time.
                                                                                                                                                        The
                                                                                                                                                        updated
                                                                                                                                                        version
                                                                                                                                                        will
                                                                                                                                                        be
                                                                                                                                                        indicated
                                                                                                                                                        by
                                                                                                                                                        an
                                                                                                                                                        updated

                                                                                                                                                        “Revised”

                                                                                                                                                        date
                                                                                                                                                        and
                                                                                                                                                        the
                                                                                                                                                        updated
                                                                                                                                                        version
                                                                                                                                                        will
                                                                                                                                                        be
                                                                                                                                                        effective
                                                                                                                                                        as
                                                                                                                                                        soon
                                                                                                                                                        as
                                                                                                                                                        it
                                                                                                                                                        is
                                                                                                                                                        accessible.
                                                                                                                                                        If
                                                                                                                                                        we
                                                                                                                                                        make
                                                                                                                                                        material
                                                                                                                                                        changes
                                                                                                                                                        to
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice,
                                                                                                                                                        we
                                                                                                                                                        may
                                                                                                                                                        notify
                                                                                                                                                        you
                                                                                                                                                        either
                                                                                                                                                        by
                                                                                                                                                        prominently
                                                                                                                                                        posting
                                                                                                                                                        a
                                                                                                                                                        notice
                                                                                                                                                        of
                                                                                                                                                        such
                                                                                                                                                        changes
                                                                                                                                                        or
                                                                                                                                                        by
                                                                                                                                                        directly
                                                                                                                                                        sending
                                                                                                                                                        you
                                                                                                                                                        a
                                                                                                                                                        notification.
                                                                                                                                                        We
                                                                                                                                                        encourage
                                                                                                                                                        you
                                                                                                                                                        to
                                                                                                                                                        review
                                                                                                                                                        this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        frequently
                                                                                                                                                        to
                                                                                                                                                        be
                                                                                                                                                        informed
                                                                                                                                                        of
                                                                                                                                                        how
                                                                                                                                                        we
                                                                                                                                                        are
                                                                                                                                                        protecting
                                                                                                                                                        your
                                                                                                                                                        information.
                                                                                                                                                    </span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="contact"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">13.
                                                                                                                                                                        HOW
                                                                                                                                                                        CAN
                                                                                                                                                                        YOU
                                                                                                                                                                        CONTACT
                                                                                                                                                                        US
                                                                                                                                                                        ABOUT
                                                                                                                                                                        THIS
                                                                                                                                                                        NOTICE?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">If
                                                                                                                                                        you
                                                                                                                                                        have
                                                                                                                                                        questions
                                                                                                                                                        or
                                                                                                                                                        comments
                                                                                                                                                        about
                                                                                                                                                        this
                                                                                                                                                        notice,
                                                                                                                                                        you
                                                                                                                                                        may
                                                                                                                                                        <span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                data-custom-class="body_text">

                                                                                                                                                                email
                                                                                                                                                                us
                                                                                                                                                                at
                                                                                                                                                                <div
                                                                                                                                                                    class="question">
                                                                                                                                                                    admin@bilwg.com
                                                                                                                                                                </div>
                                                                                                                                                                <div
                                                                                                                                                                    class="statement-end-if-in-editor">
                                                                                                                                                                </div>
                                                                                                                                                            </span></span><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                    data-custom-class="body_text">&nbsp;or
                                                                                                                                                                    contact
                                                                                                                                                                    us
                                                                                                                                                                    by
                                                                                                                                                                    post
                                                                                                                                                                    at:</span></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text"><span
                                                                                                                                                            style="font-size: 15px;"><span
                                                                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                        data-custom-class="body_text">
                                                                                                                                                                        <div
                                                                                                                                                                            class="question">
                                                                                                                                                                            Bilwg
                                                                                                                                                                            Services
                                                                                                                                                                            Private
                                                                                                                                                                            Limited
                                                                                                                                                                        </div>
                                                                                                                                                                    </span></span></span></span></span><span
                                                                                                                                                        data-custom-class="body_text"><span
                                                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">

                                                                                                                                                            </span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                    data-custom-class="body_text">
                                                                                                                                                    <div
                                                                                                                                                        class="question">
                                                                                                                                                        Dhungshil,
                                                                                                                                                        Bhimtal,
                                                                                                                                                        Nainital
                                                                                                                                                    </div>
                                                                                                                                                    <span
                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px;">

                                                                                                                                                        </span></span>
                                                                                                                                                </span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                    data-custom-class="body_text">
                                                                                                                                                    <div
                                                                                                                                                        class="question">
                                                                                                                                                        Uttarakhand
                                                                                                                                                        (263136)
                                                                                                                                                        India
                                                                                                                                                    </div>
                                                                                                                                                    <span
                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px;">



                                                                                                                                                        </span></span>
                                                                                                                                                </span><span
                                                                                                                                                    data-custom-class="body_text"><span
                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px;">
                                                                                                                                                            <div
                                                                                                                                                                class="statement-end-if-in-editor">
                                                                                                                                                            </div>
                                                                                                                                                        </span></span></span></span><span
                                                                                                                                                data-custom-class="body_text"><span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><span
                                                                                                                                                            style="color: rgb(89, 89, 89);">
                                                                                                                                                            <div
                                                                                                                                                                class="statement-end-if-in-editor">
                                                                                                                                                                <span
                                                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                                                            data-custom-class="body_text">
                                                                                                                                                                            <div
                                                                                                                                                                                class="block-component">
                                                                                                                                                                                <div
                                                                                                                                                                                    class="block-component">
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </span></span></span>
                                                                                                                                                            </div>
                                                                                                                                                        </span></span></span>
                                                                                                                                                <div
                                                                                                                                                    class="block-component">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px;"></span>
                                                                                                                                                </div>
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                                    data-custom-class="body_text">
                                                                                                                                                                    <div
                                                                                                                                                                        class="statement-end-if-in-editor">
                                                                                                                                                                        <div
                                                                                                                                                                            class="block-component">
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </span></span></span></span></span>
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div id="request"
                                                                                                                                            style="line-height: 1.5;"
                                                                                                                                            class="_mPS2id-t">
                                                                                                                                            <span
                                                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                id="control"
                                                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                        data-custom-class="heading_1">14.
                                                                                                                                                                        HOW
                                                                                                                                                                        CAN
                                                                                                                                                                        YOU
                                                                                                                                                                        REVIEW,
                                                                                                                                                                        UPDATE,
                                                                                                                                                                        OR
                                                                                                                                                                        DELETE
                                                                                                                                                                        THE
                                                                                                                                                                        DATA
                                                                                                                                                                        WE
                                                                                                                                                                        COLLECT
                                                                                                                                                                        FROM
                                                                                                                                                                        YOU?</span></strong></span></span></span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <br>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <span
                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        data-custom-class="body_text">

                                                                                                                                                        Based
                                                                                                                                                        on
                                                                                                                                                        the
                                                                                                                                                        applicable
                                                                                                                                                        laws
                                                                                                                                                        of
                                                                                                                                                        your
                                                                                                                                                        country,
                                                                                                                                                        you
                                                                                                                                                        may
                                                                                                                                                        have
                                                                                                                                                        the
                                                                                                                                                        right
                                                                                                                                                        to
                                                                                                                                                        request
                                                                                                                                                        access
                                                                                                                                                        to
                                                                                                                                                        the
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        we
                                                                                                                                                        collect
                                                                                                                                                        from
                                                                                                                                                        you,
                                                                                                                                                        change
                                                                                                                                                        that
                                                                                                                                                        information,
                                                                                                                                                        or
                                                                                                                                                        delete
                                                                                                                                                        it.
                                                                                                                                                        <span
                                                                                                                                                            class="else-block">

                                                                                                                                                            To
                                                                                                                                                            request
                                                                                                                                                            to
                                                                                                                                                            review,
                                                                                                                                                            update,
                                                                                                                                                            or
                                                                                                                                                            delete
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information,
                                                                                                                                                            please

                                                                                                                                                            fill
                                                                                                                                                            out
                                                                                                                                                            and
                                                                                                                                                            submit
                                                                                                                                                            a
                                                                                                                                                        </span>
                                                                                                                                                    </span><span
                                                                                                                                                        style="color: rgb(48, 48, 241);"><span
                                                                                                                                                            data-custom-class="body_text"><span
                                                                                                                                                                style="font-size: 15px;"><a
                                                                                                                                                                    data-custom-class="link"
                                                                                                                                                                    href="https://app.termly.io/notify/6580521d-b35e-4c81-a8f1-47dee995bbda"
                                                                                                                                                                    rel="noopener noreferrer"
                                                                                                                                                                    target="_blank">data
                                                                                                                                                                    subject
                                                                                                                                                                    access
                                                                                                                                                                    request</a></span></span></span><span
                                                                                                                                                        style="font-size: 15px;"><span
                                                                                                                                                            data-custom-class="body_text">

                                                                                                                                                        </span></span>
                                                                                                                                                    <span
                                                                                                                                                        data-custom-class="body_text">.</span></span></span>
                                                                                                                                        </div>
                                                                                                                                        <style>
                                                                                                                                            ul {
                                                                                                                                                list-style-type: square;
                                                                                                                                            }

                                                                                                                                            ul>li>ul {
                                                                                                                                                list-style-type: circle;
                                                                                                                                            }

                                                                                                                                            ul>li>ul>li>ul {
                                                                                                                                                list-style-type: square;
                                                                                                                                            }

                                                                                                                                            ol li {
                                                                                                                                                font-family: Arial;
                                                                                                                                            }
                                                                                                                                        </style>
                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <app-footer></app-footer>
</div>