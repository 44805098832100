import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'A Company provide best Website App and Software Development Services with custom UI, Features and Support, on time, on budget, application development, android app, iOS app development,bilwg services pvt ltd' },
      { name: 'author', content: 'Bilwg Services Pvt Ltd' },
      { name: 'robots', content: 'index, follow' },
      { name: 'date', content: '2023-09-23', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'revisit_after', content: '15 days' },
      { name: 'language', content: 'English' },
      { name: 'keywords', content: 'bilwg,bilwg website,about bilwg services,bilwg description,team bilwg,bilwg team,founders of bilwg services,bilwg co founders,bilwg manager,bilwg services management,android app development,iOS app development,website development,app development agency,website development agency,best development agency,bilwg services pvt ltd,software development,android app,bilwg services private limited,bilwg website, software developers, iOS app developers, android app developers,software, build website grow business, software development agency, development company,nainital,india,uttarakhand' }
    ]);
    this.title.setTitle('About Us - Bilwg Services');
  }
}
