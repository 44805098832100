<app-cursor-tracker></app-cursor-tracker>
<!-- START: Nav Area -->

<!-- End: Nav Area -->

<app-nav-bar></app-nav-bar>
<!-- START: Main Section -->
<article class="main-body">
    <h1>Meet our Excellent team members<span>.</span></h1>
    <p>We have an excellent team of developers that works together synergistically to create the highest quality
        software. Each member brings unique skills and experiences to the table, creating a powerful force that can
        produce excellent results combined.</p>
    <img src="/assets/image/about.png" alt="AboutUs" srcset="">
</article>
<!-- END: Main Section -->

<!-- START: Tech Stack Section -->
<div class="white-box">
    <section class="tech-stack">
        <h1>Tech Stack we use</h1>
        <p>Our agency's versatile tech stack reflects our deep knowledge and adaptability, spanning web development,
            mobile apps, and backend systems. This commitment ensures that every project benefits from the latest
            advancements, consistently exceeding client expectations.</p>

        <div class="tech-stack-1"></div>
        <div class="tech-stack-2"></div>
        <div class="tech-stack-3"></div>



    </section>
</div>

<!-- END: Tech Stack Section -->

<!-- START: Project Showcase Section -->
<section class="project-showcase">
    <div class="background-text"></div>
    <div class="innerBox">


        <div class="foreground-text">
            {{'{SMART}'}} <br>
            DEVELOPMENT
        </div>
        <div class="image">
            <img src="assets/image/mockup.png" alt="MockUp Design" srcset="">
        </div>
    </div>

    <div class="row">
        <p>Making your business outstanding is a science. We take it (a) seriously and (b) creatively.</p>
        <div class="btn-group">
            <a href="/project#portfolio">Check Our Portfolio</a>
            <a href="/#contact">Get In Touch</a>
        </div>
    </div>

</section>
<!-- END: Project Showcase Section -->


<!-- START: Learn More Section -->
<div class="white-box">
    <article class="learn-more">
        <h3>Learn more about our agency</h3>
        <h2>We are a digital product
            design agency</h2>
        <p>
            With a rich history of delivering successful digital product design solutions, our agency combines an
            extensive portfolio with a wealth of experience spanning years.
        </p>

        <section class="features">

            <div class="single-feature">
                <span class="highlight">5+</span>
                <span class="text">Years of <br>Experience</span>
            </div>

            <div class="single-feature">
                <span class="highlight">12</span>
                <span class="text">Competed <br>Projects</span>
            </div>

            <div class="single-feature">
                <span class="highlight">5</span>
                <span class="text">Creative <br>Teamates</span>
            </div>
        </section>


    </article>
</div>

<!-- END: Learn More Section -->


<!-- START: Team Section -->
<section class="about-team" id="team">
    <h2>The People Ready to Innovate with You</h2>
    <p>We are immensely passionate about our work, and this passion drives us to continuously improve our skills and
        push ourselves to create the best.
    </p>

    <div class="team">
        <div class="member">
            <img src="assets/image/divyanshu-sah.jpg" alt="" srcset="">
            <h4>Divyanshu Sah</h4>
            <div class="social-row">

                <a href="https://twitter.com/Divyanshu_Sah_" target="_blank">

                    <img src="assets/image/teamtwitter.svg" alt="Divyanshu Twitter" srcset=""></a>

                <a href="https://linkedin.com/in/divyanshu-sah" target="_blank">

                    <img src="assets/image/teamlinkedin.svg" alt="Divyanshu Linkedin" srcset=""></a>
            </div>
        </div>


        <div class="member">
            <img src="assets/image/abhishek-mathur.jpg" alt="" srcset="">
            <h4>Abhishek Mathur</h4>
            <div class="social-row">

                <a href="https://twitter.com/abhi_mathur01" target="_blank">

                    <img src="assets/image/teamtwitter.svg" alt="Divyanshu Twitter" srcset=""></a>

                <a href="https://www.linkedin.com/in/abhimathur01" target="_blank">

                    <img src="assets/image/teamlinkedin.svg" alt="Divyanshu Linkedin" srcset=""></a>
            </div>
        </div>


        <div class="member">
            <img src="assets/image/vinamra-jha.jpg" alt="" srcset="">
            <h4>Vinamra Jha</h4>
            <div class="social-row">

                <a href="https://twitter.com/vinamrajhaa" target="_blank">

                    <img src="assets/image/teamtwitter.svg" alt="Divyanshu Twitter" srcset=""></a>

                <a href="https://www.linkedin.com/in/vinamrajha" target="_blank">

                    <img src="assets/image/teamlinkedin.svg" alt="Divyanshu Linkedin" srcset=""></a>
            </div>
        </div>


    </div>

</section>
<!-- END: Team Section -->


<!-- START: Work Ethics Section -->
<div class="white-box">
    <article class="work-ethics">
        <h2>Our Work <b>Ethics</b></h2>
        <div class="ethics-box">

            <div class="ethics">
                <img src="assets/image/transparency.png" alt="" srcset="">
                <h4>Transparency</h4>
                <p>We seek and support honest, open and fair communication. We encourage freedom of opinion and
                    healthy debate. We trust and rely on each other, knowing that togetherness and clear
                    responsibilities are about half of the whole story.</p>
            </div>

            <div class="ethics">
                <img src="assets/image/costumer-centric.png" alt="" srcset="">
                <h4>Costumer–centric</h4>
                <p>We stand for good and simple design. Design that is functional and calm, yet bold enough to stand
                    out. Our designs are as accurate and humble as possible, however giving essential character to
                    our products.</p>
            </div>

            <div class="ethics">
                <img src="assets/image/communicate.png" alt="" srcset="">
                <h4>Communicate</h4>
                <p>Pro skills without communication skill is nothing! We're always try to established a good
                    communication with clients to receive and understand their brief, so we can give the best
                    solutions by using our design.</p>
            </div>

            <div class="ethics">
                <img src="assets/image/passion.png" alt="" srcset="">
                <h4>Passion</h4>
                <p>We are committed in heart and mind, knowing that passion and personality make a difference. We
                    use our drive and commitment to energize, engage and inspire others.</p>
            </div>


        </div>

    </article>
</div>

<!-- END: Work Ethics Section -->

<app-footer></app-footer>