export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyDZ7w_ZSBwKv7CUMA9J-kTJ53pIv5Mj5NQ",
        authDomain: "bilwg-cb1c9.firebaseapp.com",
        databaseURL: "https://bilwg-cb1c9.firebaseio.com",
        projectId: "bilwg-cb1c9",
        storageBucket: "bilwg-cb1c9.appspot.com",
        messagingSenderId: "175729096447",
        appId: "1:175729096447:web:46aafd67b6705da7747eca",
        measurementId: "G-XQ9LCT7GN5"
    },
    supabaseUrl: 'https://wrvypwfzaconqhjozcep.supabase.co/',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indydnlwd2Z6YWNvbnFoam96Y2VwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTA3NDUyNTQsImV4cCI6MjAwNjMyMTI1NH0.iF2MmvepptsgPjE5qsRd-G9ltrAkNz3PhT3ovODCmbo',
    contactUrl: "https://api.bilwg.com/api/contact"
};
