import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from '../pages/about/about.component';
import { HomeComponent } from '../pages/home/home.component';
import { ServicesComponent } from '../pages/services/services.component';
import { ProjectComponent } from '../pages/project/project.component';
import { PricingComponent } from '../pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from '../pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '../pages/terms-and-conditions/terms-and-conditions.component';
import { ConfidentialComponent } from '../pages/confidential/confidential.component';
import { LegalComponent } from '../pages/legal/legal.component';
import {
  RefundAndCancellationPolicyComponent
} from "../pages/refund-and-cancellation-policy/refund-and-cancellation-policy.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'services',
    component: ServicesComponent,
  },
  {
    path: 'project',
    component: ProjectComponent,
  },
  {
    path: 'pricing',
    component: PricingComponent,
  },

  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },

  {
    path: 'confidentiality-policy',
    component: ConfidentialComponent,
  },
  {
    path: 'legal-policy',
    component: LegalComponent,
  },
  {
    path: 'refund-and-cancellation-policy',
    component: RefundAndCancellationPolicyComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
