import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-confidential',
  templateUrl: './confidential.component.html',
  styleUrls: ['./confidential.component.scss']
})
export class ConfidentialComponent {
  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'It is our utmost priority to ensure that your privacy, ideas, and resources are safeguarded with the highest level of care and commitment. Here are some of the measures we implement to protect your valuable assets,bilwg services pvt ltd' },
      { name: 'author', content: 'Bilwg Services Pvt Ltd' },
      { name: 'robots', content: 'index, follow' },
      { name: 'date', content: '2023-09-23', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'revisit_after', content: '15 days' },
      { name: 'language', content: 'English' },
      { name: 'keywords', content: 'bilwg,bilwg confidentiality policy,bilwg services policies,android app development,iOS app development,website development,app development agency,website development agency,best development agency,bilwg services pvt ltd,software development,android app,bilwg services private limited,bilwg website, software developers, iOS app developers, android app developers,software, build webisite grow business, software development agency, development company,nainital,india,uttarakhand' }
    ]);
    this.title.setTitle('Confidentiality Policy');
  }
}
