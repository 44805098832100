import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'Discover the comprehensive Terms and Conditions of Bilwg Services, your trusted partner for exceptional Website, App, and Software Development services. We offer customized UI, feature-rich solutions, and dedicated support, delivering on time and within budget.' },
      { name: 'author', content: 'Bilwg Services Pvt Ltd' },
      { name: 'robots', content: 'index, follow' },
      { name: 'date', content: '2023-09-23', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'revisit_after', content: '15 days' },
      { name: 'language', content: 'English' },
      { name: 'keywords', content: 'bilwg terms and conditions,bilwg services policies,terms and conditions bilwg,android app development,iOS app development,website development,app development agency,website development agency,best development agency,bilwg services pvt ltd,software development,android app,bilwg services private limited,bilwg website, software developers, iOS app developers, android app developers,software,build website grow business,software development agency,development company,nainital,india,uttarakhand' }
    ]);
    this.title.setTitle('Terms and Conditions');
  }
}
