<div class="main-body">

    <app-cursor-tracker></app-cursor-tracker>
    <app-nav-bar-black></app-nav-bar-black>

    <div class="body">
        <section class="header">
            <h1>Terms & Conditions</h1>
            <p>Guidelines for a Seamless User Experience: Our Terms of Service </p>
        </section>


        <section class="text">
            <div class="entry-content wp-block-post-content is-layout-flow wp-block-post-content-is-layout-flow">
                <p>We are Bilwg Services Private Limited, doing business as Bilwg (‘<strong>Company</strong>‘,
                    ‘<strong>we</strong>‘, ‘<strong>us</strong>‘, or ‘<strong>our</strong>‘), a company registered in
                    India at Bilwg Services Pvt Ltd, Dhungshil, Bhimtal, Nainital, Bhimtal, Uttarakhand 263136.</p> <br>



                <p>We operate the website bilwg.com (the ‘<strong>Site</strong>‘), as well as any other related
                    products and services that refer or link to these legal terms (the ‘<strong>Legal Terms</strong>‘)
                    (collectively, the ‘<strong>Services</strong>‘).At Bilwg, we believe that positive change
                    starts with visibility and support. Our mission is to empower the world’s most impactful NGOs by
                    helping them establish a strong online presence and providing effective fundraising strategies. We
                    understand that NGOs play a vital role in addressing some of the world’s most pressing challenges,
                    and we’re here to amplify their voices and impact on the global stage.</p> <br>



                <p>You can contact us by phone at 8791923369, email at team@bilwg.com, or by mail to Bilwg Services
                    Pvt Ltd, Dhungshil, Bhimtal, Nainital, Bhimtal, Uttarakhand 263136,&nbsp;India.</p> <br>



                <p>These Legal Terms constitute a legally binding agreement made between you, whether personally or on
                    behalf of an entity (‘<strong>y</strong><strong>ou</strong>‘), and Bilwg Services Private Limited,
                    concerning your access to and use of the Services. You agree that by accessing the Services, you
                    have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
                    ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
                    DISCONTINUE USE IMMEDIATELY.</p> <br>



                <p>We will provide you with prior notice of any scheduled changes to the Services you are using. Changes
                    to Legal Terms will become effective fifteen (15) days after the notice is given, except if the
                    changes apply to security updates, in which case the changes will be effective immediately. By
                    continuing to use the Services after the effective date of any changes, you agree to be bound by the
                    modified terms. If you disagree with such changes, you may terminate Services as per the section ‘<a
                        href="#terms" class="__mPS2id">TERM AND TERMINATION</a>‘.The Services are intended for users who
                    are at least 13 years of age. All users who are minors in the jurisdiction in which they reside
                    (generally under the age of 18) must have the permission of, and be directly supervised by, their
                    parent or guardian to use the Services. If you are a minor, you must have your parent or guardian
                    read and agree to these Legal Terms prior to you using the Services.</p> <br>



                <p>We recommend that you print a copy of these Legal Terms for your records.</p> <br>

                <br>

                <p><strong>TABLE OF CONTENTS</strong></p>
                <br>


                <p><a href="#services" class="__mPS2id">1. OUR SERVICES</a></p> <br>



                <p><a href="#ip" class="__mPS2id">2. INTELLECTUAL PROPERTY RIGHTS</a></p> <br>



                <p><a href="#userreps" class="__mPS2id"></a><a href="#userreps" class="__mPS2id">3. USER
                        REPRESENTATIONS</a></p> <br>


                <p><a href="#purchases" class="__mPS2id">4. PURCHASES AND PAYMENT</a></p> <br>



                <p><a href="#returnno" class="__mPS2id">5. POLICY</a></p> <br>



                <p><a href="#prohibited" class="__mPS2id">6. PROHIBITED ACTIVITIES</a><a href="#ugc"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#ugc" class="__mPS2id">7. USER GENERATED CONTRIBUTIONS</a><a href="#license"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#license" class="__mPS2id">8. CONTRIBUTION LICENCE</a><a href="#reviews"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#sitemanage" class="__mPS2id">9. SERVICES MANAGEMENT</a><a href="#ppyes"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#ppyes" class="__mPS2id">10. PRIVACY POLICY</a><a href="#ppno" class="__mPS2id"></a></p>
                <br>



                <p><a href="#terms" class="__mPS2id">11. TERM AND TERMINATION</a><a href="#modifications"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#modifications" class="__mPS2id">12. MODIFICATIONS AND INTERRUPTIONS</a><a href="#law"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#law" class="__mPS2id">13. GOVERNING LAW</a><a href="#disputes" class="__mPS2id"></a></p>
                <br>



                <p><a href="#disputes" class="__mPS2id">14. DISPUTE RESOLUTION</a><a href="#corrections"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#corrections" class="__mPS2id">15. CORRECTIONS</a><a href="#disclaimer"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#disclaimer" class="__mPS2id">16. DISCLAIMER</a><a href="#liability" class="__mPS2id"></a>
                    <br>
                </p>



                <p><a href="#liability" class="__mPS2id">17. LIMITATIONS OF LIABILITY</a><a href="#indemnification"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#indemnification" class="__mPS2id">18. INDEMNIFICATION</a><a href="#userdata"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#userdata" class="__mPS2id">19. USER DATA</a><a href="#electronic" class="__mPS2id"></a></p>
                <br>



                <p><a href="#electronic" class="__mPS2id">20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                        SIGNATURES</a><a href="#california" class="__mPS2id"></a></p> <br>



                <p><a href="#california" class="__mPS2id">21. CALIFORNIA USERS AND RESIDENTS</a><a href="#misc"
                        class="__mPS2id"></a></p> <br>



                <p><a href="#misc" class="__mPS2id">22. MISCELLANEOUS</a><a href="#contact" class="__mPS2id"></a></p>
                <br>



                <p><a href="#contact" class="__mPS2id">23. CONTACT US</a></p> <br>



                <p><strong>1. OUR SERVICES</strong></p> <br>



                <p>The information provided when using the Services is not intended for distribution to or use by any
                    person or entity in any jurisdiction or country where such distribution or use would be contrary to
                    law or regulation or which would subject us to any registration requirement within such jurisdiction
                    or country. Accordingly, those persons who choose to access the Services from other locations do so
                    on their own initiative and are solely responsible for compliance with local laws, if and to the
                    extent local laws are applicable.</p> <br>



                <p>The Services are not tailored to comply with industry-specific regulations (Health Insurance
                    Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA),
                    etc.), so if your interactions would be subjected to such laws, you may not use the Services. You
                    may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</p> <br>



                <p><strong>2. INTELLECTUAL PROPERTY RIGHTS</strong></p> <br>



                <p><strong>Our intellectual property</strong></p> <br>



                <p>We are the owner or the licensee of all intellectual property rights in our Services, including all
                    source code, databases, functionality, software, website designs, audio, video, text, photographs,
                    and graphics in the Services (collectively, the ‘Content’), as well as the trademarks, service
                    marks, and logos contained therein (the ‘Marks’).</p> <br>



                <p>Our Content and Marks are protected by copyright and trademark laws (and various other intellectual
                    property rights and unfair competition laws) and treaties in the United States and around the world.
                </p> <br>



                <p>The Content and Marks are provided in or through the Services ‘AS IS’ for your personal,
                    non-commercial use or internal business purpose only.</p> <br>



                <p><strong>Your use of our Services</strong></p> <br>



                <p>Subject to your compliance with these Legal Terms, including the ‘<a href="#prohibited"
                        class="__mPS2id">PROHIBITED ACTIVITIES</a>‘ section below, we grant you a non-exclusive,
                    non-transferable, revocable licence to:</p> <br>


                <div class="ul">
                    <ul>
                        <li>access the Services; and</li>



                        <li>download or print a copy of any portion of the Content to which you have properly gained
                            access.
                        </li>
                    </ul>
                </div>




                <p>solely for your personal, non-commercial use or internal business purpose.</p> <br>



                <p>Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no
                    Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly
                    displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for
                    any commercial purpose whatsoever, without our express prior written permission.</p> <br>



                <p>If you wish to make any use of the Services, Content, or Marks other than as set out in this section
                    or elsewhere in our Legal Terms, please address your request to: team@bilwg.com. If we ever
                    grant you the permission to post, reproduce, or publicly display any part of our Services or
                    Content, you must identify us as the owners or licensors of the Services, Content, or Marks and
                    ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or
                    displaying our Content.</p> <br>



                <p>We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.</p>
                <br>



                <p>Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms
                    and your right to use our Services will terminate immediately.</p> <br>



                <p><strong>Your submissions</strong></p> <br>



                <p>Please review this section and the ‘<a href="#prohibited" class="__mPS2id">PROHIBITED ACTIVITIES</a>‘
                    section carefully prior to using our Services to understand the (a) rights you give us and (b)
                    obligations you have when you post or upload any content through the Services.</p> <br>



                <p><strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea,
                    feedback, or other information about the Services (‘Submissions’), you agree to assign to us all
                    intellectual property rights in such Submission. You agree that we shall own this Submission and be
                    entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise,
                    without acknowledgment or compensation to you.</p> <br>



                <p><strong>You are responsible for what you post or upload:</strong> By sending us Submissions through
                    any part of the Services you:</p> <br>


                <div class="ul">
                    <ul>
                        <li>confirm that you have read and agree with our ‘<a href="#prohibited"
                                class="__mPS2id">PROHIBITED
                                ACTIVITIES</a>‘ and will not post, send, publish, upload, or transmit through the
                            Services
                            any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying,
                            abusive, discriminatory, threatening to any person or group, sexually explicit, false,
                            inaccurate, deceitful, or misleading;</li>



                        <li>to the extent permissible by applicable law, waive any and all moral rights to any such
                            Submission;</li>



                        <li>warrant that any such Submission are original to you or that you have the necessary rights
                            and
                            licences to submit such Submissions and that you have full authority to grant us the
                            above-mentioned rights in relation to your Submissions; and</li>



                        <li>warrant and represent that your Submissions do not constitute confidential information.</li>
                    </ul>
                </div>

                <br>


                <p>You are solely responsible for your Submissions</p> <br>



                <p>and you expressly agree to reimburse us for any and all losses that we may suffer because of your
                    breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable
                    law.</p> <br>



                <p><a></a><strong>3.&nbsp;USER REPRESENTATIONS</strong></p> <br>



                <p>By using the Services, you represent and warrant that:&nbsp;(1) you have the legal capacity and you
                    agree to comply with these Legal Terms; (2) you are not under the age of 13;&nbsp;(3) you are not a
                    minor in the jurisdiction in which you reside, or if a minor, you have received parental permission
                    to use the Services; (4) you will not access the Services through automated or non-human means,
                    whether through a bot, script or otherwise; (5) you will not use the Services for any illegal or
                    unauthorised purpose; and (6) your use of the Services will not violate any applicable law or
                    regulation.</p> <br>



                <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the
                    right to suspend or terminate your account and refuse any and all current or future use of the
                    Services (or any portion thereof).</p> <br>



                <p><a></a><strong>4.&nbsp;PURCHASES AND PAYMENT</strong></p> <br>



                <p>We accept the following forms of payment:</p> <br>


                <div class="ul">
                    <p>– &nbsp;Bank Transfer</p>



                    <p>– &nbsp;UPI</p>
                </div>


                <br>

                <p>You agree to provide current, complete, and accurate purchase and account information for all
                    purchases made via the Services. You further agree to promptly update account and payment
                    information, including email address, payment method, and payment card expiration date, so that we
                    can complete your transactions and contact you as needed. Sales tax will be added to the price of
                    purchases as deemed required by us. We may change prices at any time. All payments shall be&nbsp;in
                    Indian Rupee.</p> <br>



                <p>You agree to pay all charges at the prices then in effect for your purchases and any applicable
                    shipping fees, and you authorise us to charge your chosen payment provider for any such amounts upon
                    placing your order. We reserve the right to correct any errors or mistakes in pricing, even if we
                    have already requested or received payment.</p> <br>



                <p>We reserve the right to refuse any order placed through the Services. We may, in our sole discretion,
                    limit or cancel quantities purchased per person, per household, or per order. These restrictions may
                    include orders placed by or under the same customer account, the same payment method, and/or orders
                    that use the same billing or shipping address. We reserve the right to limit or prohibit orders
                    that, in our sole judgement, appear to be placed by dealers, resellers, or distributors.</p> <br>



                <p><strong>5. POLICY</strong></p> <br>



                <p>All sales are final and no refund will be issued.</p> <br>



                <p><strong>6.&nbsp;PROHIBITED ACTIVITIES</strong></p> <br>



                <p>You may not access or use the Services for any purpose other than that for which we make the Services
                    available. The Services may not be used in connection with any commercial endeavours except those
                    that are specifically endorsed or approved by us.</p> <br>



                <p>As a user of the Services, you agree not to:</p> <br>


                <div class="ul">
                    <ul>
                        <li>Systematically retrieve data or other content from the Services to create or compile,
                            directly
                            or indirectly, a collection, compilation, database, or directory without written permission
                            from
                            us.</li>



                        <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive
                            account information such as user passwords.</li>



                        <li>Circumvent, disable, or otherwise interfere with security-related features of the Services,
                            including features that prevent or restrict the use or copying of any Content or enforce
                            limitations on the use of the Services and/or the Content contained therein.</li>



                        <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>



                        <li>Use any information obtained from the Services in order to harass, abuse, or harm another
                            person.</li>



                        <li>Make improper use of our support services or submit false reports of abuse or misconduct.
                        </li>



                        <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>



                        <li>Engage in unauthorised framing of or linking to the Services.</li>



                        <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other
                            material, including excessive use of capital letters and spamming (continuous posting of
                            repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the
                            Services or modifies, impairs, disrupts, alters, or interferes with the use, features,
                            functions, operation, or maintenance of the Services.</li>



                        <li>Engage in any automated use of the system, such as using scripts to send comments or
                            messages,
                            or using any data mining, robots, or similar data gathering and extraction tools.</li>



                        <li>Delete the copyright or other proprietary rights notice from any Content.</li>



                        <li>Attempt to impersonate another user or person or use the username of another user.</li>



                        <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive
                            or
                            active information collection or transmission mechanism, including without limitation, clear
                            graphics interchange formats (‘gifs’), 1×1 pixels, web bugs, cookies, or other similar
                            devices
                            (sometimes referred to as ‘spyware’ or ‘passive collection mechanisms’ or ‘pcms’).</li>



                        <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or
                            services
                            connected to the Services.</li>



                        <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing
                            any
                            portion of the Services to you.</li>



                        <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the
                            Services, or any portion of the Services.</li>



                        <li>Copy or adapt the Services’ software, including but not limited to Flash, PHP, HTML,
                            JavaScript,
                            or other code.</li>



                        <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer
                            any
                            of the software comprising or in any way making up a part of the Services.</li>



                        <li>Except as may be the result of standard search engine or Internet browser usage, use,
                            launch,
                            develop, or distribute any automated system, including without limitation, any spider,
                            robot,
                            cheat utility, scraper, or offline reader that accesses the Services, or use or launch any
                            unauthorised script or other software.</li>



                        <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>



                        <li>Make any unauthorised use of the Services, including collecting usernames and/or email
                            addresses
                            of users by electronic or other means for the purpose of sending unsolicited email, or
                            creating
                            user accounts by automated means or under false pretences.</li>



                        <li>Use the Services as part of any effort to compete with us or otherwise use the Services
                            and/or
                            the Content for any revenue-generating endeavour or commercial enterprise.</li>
                    </ul>
                </div>


                <br>

                <p><strong>7.&nbsp;USER GENERATED CONTRIBUTIONS</strong></p> <br>



                <p>The Services does not offer users to submit or post content.</p> <br>



                <p><strong>8.&nbsp;CONTRIBUTION LICENCE</strong></p> <br>



                <p>You and Services agree that we may access, store, process, and use any information and personal data
                    that you provide following the terms of the Privacy Policy and your choices (including settings).
                </p> <br>



                <p>By submitting suggestions or other feedback regarding the Services, you agree that we can use and
                    share such feedback for any purpose without compensation to you.</p> <br>



                <p><strong>9.&nbsp;SERVICES MANAGEMENT</strong></p> <br>



                <p>We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these
                    Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates
                    the law or these Legal Terms, including without limitation, reporting such user to law enforcement
                    authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit
                    the availability of, or disable (to the extent technologically feasible) any of your Contributions
                    or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to
                    remove from the Services or otherwise disable all files and content that are excessive in size or
                    are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed
                    to protect our rights and property and to facilitate the proper functioning of the Services.</p>
                <br>



                <p><strong>10.&nbsp;PRIVACY POLICY</strong></p> <br>



                <p>We care about data privacy and security. Please review our Privacy Policy:<strong>&nbsp;<a
                            href="https://bilwg.com/privacy-policy/" target="_blank"
                            rel="noreferrer noopener">https://bilwg.com/privacy-policy/</a></strong>. By using the
                    Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms.
                    Please be advised the Services are hosted in India. If you access the Services from any other region
                    of the world with laws or other requirements governing personal data collection, use, or disclosure
                    that differ from applicable laws in India, then through your continued use of the Services, you are
                    transferring your data to India, and you expressly consent to have your data transferred to and
                    processed in India. Further, we do not knowingly accept, request, or solicit information from
                    children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online
                    Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided
                    personal information to us without the requisite and verifiable parental consent, we will delete
                    that information from the Services as quickly as is reasonably practical.</p> <br>



                <p><strong>11.&nbsp;TERM AND TERMINATION</strong></p> <br>



                <p>These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING
                    ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
                    WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP
                    ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH
                    OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW
                    OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE ANY CONTENT OR
                    INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p> <br>



                <p>If we terminate or suspend your account for any reason, you are prohibited from registering and
                    creating a new account under your name, a fake or borrowed name, or the name of any third party,
                    even if you may be acting on behalf of the third party. In addition to terminating or suspending
                    your account, we reserve the right to take appropriate legal action, including without limitation
                    pursuing civil, criminal, and injunctive redress.</p> <br>



                <p><strong>12.&nbsp;MODIFICATIONS AND INTERRUPTIONS</strong></p> <br>



                <p>We reserve the right to change, modify, or remove the contents of the Services at any time or for any
                    reason at our sole discretion without notice. However, we have no obligation to update any
                    information on our Services. We will not be liable to you or any third party for any modification,
                    price change, suspension, or discontinuance of the Services.</p> <br>



                <p>We cannot guarantee the Services will be available at all times. We may experience hardware,
                    software, or other problems or need to perform maintenance related to the Services, resulting in
                    interruptions, delays, or errors. We reserve the right to change, revise, update, suspend,
                    discontinue, or otherwise modify the Services at any time or for any reason without notice to you.
                    You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your
                    inability to access or use the Services during any downtime or discontinuance of the Services.
                    Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services
                    or to supply any corrections, updates, or releases in connection therewith.</p> <br>



                <p><strong>13.&nbsp;GOVERNING LAW</strong></p> <br>



                <p>These Legal Terms shall be governed by and defined following the laws of India. Bilwg Services
                    Private Limited and yourself irrevocably consent that the courts of India shall have exclusive
                    jurisdiction to resolve any dispute which may arise in connection with these Legal Terms.</p> <br>



                <p><strong>14.&nbsp;DISPUTE RESOLUTION</strong></p> <br>



                <p>You agree to irrevocably submit all disputes related to these Legal Terms or the legal relationship
                    established by these Legal Terms to the jurisdiction of the India courts. Bilwg Services Private
                    Limited shall also maintain the right to bring proceedings as to the substance of the matter in the
                    courts of the country where you reside or, if these Legal Terms are entered into in the course of
                    your trade or profession, the state of your principal place of business.</p> <br>



                <p><strong>15.&nbsp;CORRECTIONS</strong></p> <br>



                <p>There may be information on the Services that contains typographical errors, inaccuracies, or
                    omissions, including descriptions, pricing, availability, and various other information. We reserve
                    the right to correct any errors, inaccuracies, or omissions and to change or update the information
                    on the Services at any time, without prior notice.</p> <br>



                <p><strong>16. DISCLAIMER</strong></p> <br>



                <p>THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES
                    WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                    EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                    LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                    NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                    SERVICES’ CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND
                    WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
                    CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
                    FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE
                    SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                    INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN
                    HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
                    (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
                    INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
                    THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
                    SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
                    WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
                    PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                    PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM
                    OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                </p> <br>



                <p><strong>17.&nbsp;LIMITATIONS OF LIABILITY</strong></p> <br>



                <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                    ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
                    LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES,
                    EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p> <br>



                <p><strong>18.&nbsp;INDEMNIFICATION</strong></p> <br>



                <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all
                    of our respective officers, agents, partners, and employees, from and against any loss, damage,
                    liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third
                    party due to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3) any
                    breach of your representations and warranties set forth in these Legal Terms; (4) your violation of
                    the rights of a third party, including but not limited to intellectual property rights; or (5) any
                    overt harmful act toward any other user of the Services with whom you connected via the Services.
                    Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive
                    defence and control of any matter for which you are required to indemnify us, and you agree to
                    cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to
                    notify you of any such claim, action, or proceeding which is subject to this indemnification upon
                    becoming aware of it.</p> <br>



                <p><strong>19.&nbsp;USER DATA</strong></p> <br>



                <p>We will maintain certain data that you transmit to the Services for the purpose of managing the
                    performance of the Services, as well as data relating to your use of the Services. Although we
                    perform regular routine backups of data, you are solely responsible for all data that you transmit
                    or that relates to any activity you have undertaken using the Services. You agree that we shall have
                    no liability to you for any loss or corruption of any such data, and you hereby waive any right of
                    action against us arising from any such loss or corruption of such data.</p> <br>



                <p><strong>20.&nbsp;ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong></p> <br>



                <p>Visiting the Services, sending us emails, and completing online forms constitute electronic
                    communications. You consent to receive electronic communications, and you agree that all agreements,
                    notices, disclosures, and other communications we provide to you electronically, via email and on
                    the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE
                    TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
                    DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
                    SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules,
                    ordinances, or other laws in any jurisdiction which require an original signature or delivery or
                    retention of non-electronic records, or to payments or the granting of credits by any means other
                    than electronic means.</p> <br>



                <p><strong>21.&nbsp;CALIFORNIA USERS AND RESIDENTS</strong></p> <br>



                <p>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance
                    Unit of the Division of Consumer Services of the California Department of Consumer Affairs in
                    writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at
                    (800) 952-5210 or (916) 445-1254.</p> <br>



                <p><strong>22.&nbsp;MISCELLANEOUS</strong></p> <br>



                <p>These Legal Terms and any policies or operating rules posted by us on the Services or in respect to
                    the Services constitute the entire agreement and understanding between you and us. Our failure to
                    exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of
                    such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may
                    assign any or all of our rights and obligations to others at any time. We shall not be responsible
                    or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable
                    control. If any provision or part of a provision of these Legal Terms is determined to be unlawful,
                    void, or unenforceable, that provision or part of the provision is deemed severable from these Legal
                    Terms and does not affect the validity and enforceability of any remaining provisions. There is no
                    joint venture, partnership, employment or agency relationship created between you and us as a result
                    of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed
                    against us by virtue of having drafted them. You hereby waive any and all defences you may have
                    based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to
                    execute these Legal Terms.</p> <br>



                <p><strong>23.&nbsp;CONTACT US</strong></p> <br>



                <p>In order to resolve a complaint regarding the Services or to receive further information regarding
                    use of the Services, please contact us at:</p> <br>



                <p><strong>Bilwg Services Private Limited</strong><strong></strong></p> <br>

                <p><strong>Dhungshil, Bhimtal, Nainital</strong></p> <br>



                <p><strong>Bhimtal, Uttarakhand 263136</strong><strong></strong></p> <br>



                <p><strong>India</strong></p> <br>



                <p><strong>Phone: +91 8791923369</strong></p><br>



                <p><strong>admin@bilwg.com</strong><br></p><br>
            </div>
        </section>
    </div>

    <app-footer></app-footer>
</div>