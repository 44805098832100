<!-- <div class="logoBox">
    <img src="assets/image/bilwg-white.png" alt="" srcset="">
</div> -->
<div class="nav">
    <a href="#contact">
        <div class="navElement">Contact</div>
    </a>
    <div class="navElement" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}"
        [routerLink]="['/services']">Services</div>


    <div class="navElement" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}"
        [routerLink]="['/project']">Project</div>


    <div class="navElement" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}"
        [routerLink]="['/about']">About</div>

    <div class="navElement" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
        Home</div>
</div>

<div class="mobileNavBar">
    <div class="row">
        <img (click)="isOpen=!isOpen" src="assets/image/hamBurger.svg" alt="" srcset="">
    </div>
    <div class="hidden" *ngIf="isOpen">
        <div class="closeBtn"><img (click)="isOpen=!isOpen" src="assets/image/closeBtn.svg"></div>
        <div class="navElement" [routerLink]="['/']">Home</div>
        <div class="navElement" [routerLink]="['/about']">About</div>
        <div class="navElement" [routerLink]="['/project']">Project</div>
        <div class="navElement" [routerLink]="['/services']">Service</div>
        <a class="navElement" href="/#contact"> Contact</a>


    </div>
</div>