<!--Sart: Footer-->

<div class="footer-wrapper">
    <section class="footer">


        <div class="mainFooter">
            <div class="socialFooter">
                <div class="socialsRow">
                    <a href="https://twitter.com/Bilwg_Services" target="_blank" class="socialIcon">
                        <img src="assets/image/icon/twitterLogo.svg" alt="">
                    </a>
                    <a href="https://in.linkedin.com/company/bilwg" target="_blank" class="socialIcon">

                        <img src="assets/image/icon/linkedInLogo.svg" alt="">
                    </a>
                    <a href="https://www.instagram.com/bilwg_services" target="_blank" class="socialIcon">

                        <img src="assets/image/icon/instagramLogo.svg" alt="">
                    </a>
                    <a href="https://github.com/bilwg-services" target="_blank" class="socialIcon">
                        <img src="assets/image/icon/githubLogo.svg" alt="">
                    </a>
                </div>

                <p class="signupText">Sign up for personalized offers</p>

                <div class="signupBar">

                    <div class="search">
                        <input type="text" placeholder="Enter your email">
                    </div>

                    <button>
                        <img src="assets/image/icon/sendIcon.svg" alt="search">
                    </button>

                    <!-- <p class="text">
                            Enter your email
                        </p>
                        <div class="sendIcon">
                        </div> -->
                </div>
            </div>

            <div class="footerMain">
                <p class="bold">Services</p>
                <p class="bold">Features</p>
                <p class="bold">Company</p>
                <p class="bold">Legal & Terms</p>

                <a href="/services">Web Development</a>
                <a href="/pricing">Pricing</a>
                <a href="/about">About Bilwg</a>
                <a href="/privacy-policy">Privacy Policy</a>

                <a href="/services">App Development</a>
                <a target="_blank" href="https://calendly.com/bilwg/getting-in-touch">Book a Meeting</a>
                <a href="/confidentiality-policy">Confidential</a>
                <a href="/terms-and-conditions">Terms and Conditions</a>

                <a href="/services">Designing</a>
                <a href="/project">Projects</a>
                <a href="/about#team">Team</a>
                <a href="/legal-policy">Legal</a>

                <a href="/services">Custom Software</a>
                <a href="https://posts.gle/WHXXaN" target="_blank">Offers</a>
                <a href="/#testimonial">Reviews</a>
                <a href="/refund-and-cancellation-policy">Refund and Cancellation</a>

              <a href="/services"></a>
              <a href="https://posts.gle/WHXXaN" target="_blank"></a>
              <a href="/#testimonial"></a>
              <a href="/assets/sitemap.xml">Sitemap</a>

            </div>
        </div>

        <hr class="hrLine">

        <p class="copyright">
            &copy; Copyright 2023 Bilwg Services. All rights reserved.
        </p>
    </section>
</div>

<!--End: Footer-->
