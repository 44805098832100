<app-cursor-tracker></app-cursor-tracker>
<app-nav-bar-black></app-nav-bar-black>
<!-- START: Main Section -->
<div class="main-body">

  <article class="main-section">
    <br><br>
    <h1>Amazing Services to unlock your full potential business.</h1>

    <a href="/project#portfolio" class="button">View project
    </a>
  </article>

  <div class="image-container">
    <img src="assets/image/project/phone1.png" alt="Image 1" class="image1" #image1>
    <img src="assets/image/project/phone2.png" alt="Image 2" class="image2" #image2>
    <img src="assets/image/project/phone3.png" alt="Image 3" class="image3" #image3>
  </div>
</div>

<!-- END: Main Section -->


<!-- START: Project Showcase Section -->
<section class="project-showcase">
  <div class="background-text"></div>
  <div class="innerBox">


    <div class="foreground-text">
      {{'{SMART}'}} <br>
      DEVELOPMENT
    </div>
    <div class="image">
      <img src="assets/image/mockup.png" alt="MockUp Design" srcset="">
    </div>
  </div>

  <div class="row">
    <p>Making your business outstanding is a science. We take it (a) seriously and (b) creatively.</p>
    <div class="btn-group">
      <a href="/project#portfolio">Check Our Portfolio</a>
      <a href="/#contact">Get In Touch</a>
    </div>
  </div>

</section>
<!-- END: Project Showcase Section -->

<!--START: Portfolio Section-->
<section class="portfolio" id="portfolio">
  <div class="project">

    <img class="img" src="assets/image/project/project-s-1.png" alt="">

    <h2 class="projectName">Sain: Simplifying Healthcare </h2>
    <div class="projectTagRow">
      <p class="tag">HealthTech</p>
      <p class="tag">Mobile App</p>
    </div>
    <p class="projectDescription">
      Introducing "Sain," your all-in-one healthcare companion. Easily book doctor appointments, select hospitals, and
      track medications through our user-friendly app. Experience seamless healthcare management with Sain, making your
      health journey more accessible and efficient.
    </p>
  </div>

  <div class="project">

    <img class="img" src="assets/image/project/project-s-2.png" alt="">

    <h2 class="projectName">PGURUS: News & Analysis App</h2>
    <div class="projectTagRow">
      <p class="tag">News</p>
      <p class="tag">Mobile App</p>
    </div>
    <p class="projectDescription">Discover Pgurus, your trusted news source. Access news through our app for a reliable
      and insightful information experience. Stay informed with Pgurus, your gateway to credible news.
    </p>
  </div>

  <div class="project">

    <img class="img" src="assets/image/project/project-s-3.png" alt="">

    <h2 class="projectName">Habitparty: Build Better Habits Together </h2>
    <div class="projectTagRow">
      <p class="tag">Personal Growth</p>
      <p class="tag">Self Improvement</p>
      <p class="tag">iOS App</p>
    </div>
    <p class="projectDescription">
      Habitparty helps you stay on top of your habits by creating friendly competitions. Competing with friends helps
      make hitting your goals fun! Simply invite friends with the send of a text. Use Habitparty to compete with your
      friends to develop better habits. Habits include: Biking, Dieting, Hydrating, Meditating, Reading, Running,
      Sleeping and Strength Training.
    </p>
  </div>

  <div class="project">

    <img class="img" src="assets/image/project/project-s-4.png" alt="">

    <h2 class="projectName">Sree Iyer - Books, Ebooks & Art </h2>
    <div class="projectTagRow">
      <p class="tag">Ecommerce</p>
      <p class="tag">Website & Mobile App</p>
    </div>
    <p class="projectDescription">
      Sree Iyer App–for buying his Books, E-books and rare, precision-crafted wood art <br><br>

      Discover the Sree Iyer app, your ultimate destination for purchasing his books, e-books, and exquisite,
      precision-crafted wooden art. Sree Iyer, a multifaceted personality, has not only established himself as an
      acclaimed author but also as a prolific inventor. With a collection of five non-fiction and five fiction books
      already published, he continues to work on captivating new projects that will soon grace the literary world.
    </p>
  </div>

  <div class="project">

    <img class="img" src="assets/image/project/project-s-5.png" alt="">

    <h2 class="projectName">BravoMe - Student & Teacher Recognition</h2>
    <div class="projectTagRow">
      <p class="tag">Ecommerce</p>
      <p class="tag">SaaS</p>
    </div>
    <p class="projectDescription">
      BravoMe is a dynamic Student & Staff Recognition Application. It encourages active student participation in
      academics and co-curricular activities, fostering a culture of excellence through acknowledgment and rewards,
      creating an environment where achievement thrives.
    </p>
  </div>

  <div class="project">

    <img class="img" src="assets/image/project/project-s-6.png" alt="">

    <h2 class="projectName">Eat Better</h2>
    <div class="projectTagRow">
      <p class="tag">Food Odering</p>
      <p class="tag">UI/UX</p>
    </div>
    <p class="projectDescription">
      Eat Better: Elevate your dining with a health-focused online delivery app. Enjoy chef-prepared, wholesome meals
      tailored to your preferences. Sustainable packaging and local partnerships for a conscious dining experience.
      Embrace a tastier, healthier you today.
    </p>
  </div>

</section>
<!--END: Portfolio Section-->


<div class="project-container">
  <img src="assets/image/project/project-m-1.png" class="image" alt="Project 1">
  <img src="assets/image/project/project-m-2.png" class="image" alt="Project 2">
  <img src="assets/image/project/project-m-3.png" class="image" alt="Project 3">
  <img src="assets/image/project/project-m-4.png" class="image" alt="Project 4">
</div>


<app-footer></app-footer>