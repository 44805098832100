import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './core/app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { HomeComponent } from './pages/home/home.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { ProjectComponent } from './pages/project/project.component';
import { FooterComponent } from './widget/footer/footer.component';
import { NavBarComponent } from './widget/nav-bar/nav-bar.component';
import { NavBarBlackComponent } from './widget/nav-bar-black/nav-bar-black.component';


import { AngularFireModule } from "@angular/fire/compat";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { getPerformance, providePerformance } from "@angular/fire/performance";
import { ReactiveFormsModule } from "@angular/forms";
import { environment } from 'src/environment/enviroment';
import { CursorTrackerComponent } from './widget/cursor-tracker/cursor-tracker.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { ConfidentialComponent } from './pages/confidential/confidential.component';
import { LegalComponent } from './pages/legal/legal.component';
import { RefundAndCancellationPolicyComponent } from './pages/refund-and-cancellation-policy/refund-and-cancellation-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ServicesComponent,
    HomeComponent,
    PricingComponent,
    ProjectComponent,
    FooterComponent,
    NavBarComponent,
    NavBarBlackComponent,
    CursorTrackerComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ConfidentialComponent,
    LegalComponent,
    RefundAndCancellationPolicyComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
  ],
  providers: [provideClientHydration()],
  bootstrap: [AppComponent]
})
export class AppModule { }
