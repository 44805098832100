import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent {
  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'This Legal Policy outlines the terms and conditions that govern your use of our website and services. By accessing or using our website, you agree to abide by the terms outlined herein,Welcome to Bilwg Services Private Limited, a software development company dedicated to providing high-quality software solutions., bilwg services pvt ltd' },
      { name: 'author', content: 'Bilwg Services Pvt Ltd' },
      { name: 'robots', content: 'index, follow' },
      { name: 'date', content: '2023-09-23', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'revisit_after', content: '15 days' },
      { name: 'language', content: 'English' },
      { name: 'keywords', content: 'bilwg services privacy policy, bilwg services legal policy,bilwg services nda,bilwg legal,bilwg site,bilwg services,bilwg services pvt ltd' }
    ]);
    this.title.setTitle('Legal Policy');
  }
}
