import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'Your Data, Your Rights: Our Commitment to Privacy and Security,bilwg services pvt ltd,read our privacy policy to understand your rights and choices' },
      { name: 'author', content: 'Bilwg Services Pvt Ltd' },
      { name: 'robots', content: 'index, follow' },
      { name: 'date', content: '2023-09-23', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'revisit_after', content: '15 days' },
      { name: 'language', content: 'English' },
      { name: 'keywords', content: 'bilwg privacy policy,privacy policy bilwg,bilwg services policies,software development,android app development,bilwg services private limited,bilwg website,software developers,iOS app developers,android app developers,software,build website grow business,software development agency,development company' }
    ]);
    this.title.setTitle('Privacy Policy');
  }
}
