<app-cursor-tracker></app-cursor-tracker>
<app-nav-bar></app-nav-bar>
<!--Start: Services Header-->
<article class="services-header">
    <h1>Amazing Services to unlock your full potential business.</h1>
    <p>Unlock the boundless potential of your business with our remarkable range of services. From strategic planning
        and innovative solutions to tailored support, we're dedicated to helping you achieve remarkable success. Explore
        our offerings today and take your business to new heights. </p>

    <section class="search-bar">
        <div class="search">
            <img src="assets/image/search.svg" alt="search">
            <input type="text" placeholder="Looking for an IT Services?">
        </div>
        <button>Search</button>
    </section>
</article>
<!--End: Services Header-->

<!--Start: Service-Box-->
<section class="single-service">


    <article class="service-box">
        <h1>Web Development</h1>
        <p>We offer custom web development services that are tailored to your specific needs. We use the latest
            technologies to create websites that are responsive and user-friendly. Our team of skilled developers
            will work closely with you to ensure that your vision is realized.</p>
    </article>
    <img class="service-image" src="assets/image/web-development.png" alt="Web Development">
</section>


<section class="single-service">
    <article class="service-box">
        <h1>App Development</h1>
        <p>We have custom app development services for all major platforms. The apps we develop are user-friendly
            and responsive. The highly skilled developers, as well as designers, will help you bring your app idea
            to life. From modern designs to the latest features, we will create an app that is sure to impress your
            users.</p>
        <!-- <a class="read-more-btn">Read More
                <img src="public/images/arrow.svg" alt="" srcset="">
            </a> -->
    </article>
    <img class="service-image" src="assets/image/app-development.png" alt="Web Development">
</section>


<section class="single-service">
    <article class="service-box">
        <h1>Designing</h1>
        <p>Our amazing set of experienced UI/UX designers works on all aspects of design, from user experience to
            visual design. We create modern beautiful and intuitive designs that are easy to use and enjoyable for
            your users.</p>
        <!-- <a class="read-more-btn">Read More
                <img src="public/images/arrow.svg" alt="" srcset="">
            </a> -->
    </article>
    <img class="service-image" src="assets/image/designing.png" alt="Web Development">
</section>


<section class="single-service">
    <article class="service-box margin">
        <h1>Marketing and Management</h1>
        <p>We offer marketing and management services to help you promote and manage your website or app. We will
            help you create a marketing plan that is tailored to your specific needs. We also offer website and app
            management services to keep your site or app running smoothly.</p>

        <!-- <a class="read-more-btn">Read More
                <img src="public/images/arrow.svg" alt="" srcset="">
            </a> -->
    </article>
    <img class="service-image" src="assets/image/marketing.png" alt="Web Development">
</section>

<!--End: Service-Box-->

<!--Start: Development Process Section-->
<section class="developmentProcess">

    <div class="heading">What does our development process look like?</div>
    <p class="description">We make the whole development process seamless and smooth. Have a look at the same below
        - </p>

    <div class="process">

        <h1 class="processHeading"><span>Product</span> Design</h1>

        <div class="processRow">
            <div class="processBox">
                <img src="" alt="">
                <img class="processBoxImage" src="assets/image/photo1.png" alt="">
                <div class="processBoxHeading">Business Analysis</div>
                <div class="processBoxContent">We start by understanding your business and your specific needs. We
                    then conduct a thorough analysis of your target market and your competition. This helps us to
                    develop a clear strategy for your project.
                </div>
            </div>
            <div class="processBox">
                <img class="processBoxImage" src="assets/image/photo2.png" alt="">
                <div class="processBoxHeading">UX Design</div>
                <div class="processBoxContent">Once we have a solid understanding of your business and your goals,
                    we begin designing the user experience. We take into account the user dynamic and how your users
                    will interact with your site or app and design accordingly.
                </div>
            </div>
        </div>

        <h1 class="processHeading"><span>Product</span> Development</h1>

        <div class="processRow">
            <div class="processBox">
                <img src="" alt="">
                <img class="processBoxImage" src="assets/image/photo3.png" alt="">
                <div class="processBoxHeading">Ui Design</div>
                <div class="processBoxContent">The next step is to design the user interface. This is the look and
                    feel of your site or app. We work to create a visually appealing and modern design that is also
                    easy to use.
                </div>
            </div>
            <div class="processBox">
                <img class="processBoxImage" src="assets/image/photo4.png" alt="">
                <div class="processBoxHeading">Backend</div>
                <div class="processBoxContent">We then move on to the backend development. This is where we create
                    the functionality of your site or app. We make sure everything works correctly and is able to
                    scale as needed. The backend is also where we integrate any third-party services you may need.
                </div>
            </div>
        </div>

        <div class="processRow">
            <div class="processBox">
                <img src="" alt="">
                <img class="processBoxImage" src="assets/image/photo5.png" alt="">
                <div class="processBoxHeading">Mobile App Development</div>
                <div class="processBoxContent">Once we develop the backend modules and APIs, we move to
                    implement the same on the front end.
                </div>
            </div>
            <div class="processBox">
                <img class="processBoxImage" src="assets/image/photo6.png" alt="">
                <div class="processBoxHeading">Testing</div>
                <div class="processBoxContent"> Before launch, we thoroughly test your site or app to ensure there
                    are no issues. We also test for compatibility across all devices and browsers. At this stage, we
                    also finalize your content and make sure everything is ready for launch.
                </div>
            </div>
        </div>


        <h1 class="processHeading"><span>Support & Maintenance</span></h1>
        <div class="processRow">
            <div class="processBox">
                <div class="processBoxContent">Maintenance and Support are provided as needed to ensure that your
                    software is always up-to-date and compliant with industry standards. This might include security
                    updates, bug fixes, and new feature development.
                </div>
            </div>
        </div>


    </div>

</section>
<!--End: Development Process Section-->


<div class="bg-with-color">

    <!--Start: WorkFlow Section-->

    <article class="workFlow">
        <h1 class="workFlowHeading">
            OUR WORKFLOW
        </h1>

        <!-- <div class="workFlowBox">
        <div class="single">
            <h2 class="subHeading">Book Your Meeting</h2>
            <p class="description">Select the best which you are looking
                for</p>
        </div>

        <div class="single">
            <h2 class="subHeading">Meetings</h2>
            <p class="description">Our team will have a conference with
                you and know about your requirements.
            </p>
        </div>

        <div class="single">
            <h2 class="subHeading">Development Phase</h2>
            <p class="description">Start Development according to timelime. Weekly Updates and Live Demo will also
                be provided

            </p>
        </div>

        <div class="single">
            <h2 class="subHeading">Per Development Phase
            </h2>
            <p class="description">Ux / Ui, Logo, Graphic Designing

            </p>
        </div>

        <div class="single">
            <h2 class="subHeading">Testing</h2>
            <p class="description">This is the period for testing the product. We will provide the final Product to
                test it properly also people from our internal team will also test.
            </p>
        </div>

        <div class="single">
            <h2 class="subHeading">Maintenace</h2>
            <p class="description">Its not yet over, We can provide you with with the Security and maintenance
                service for your project

            </p>
        </div>
    </div> -->


        <img src="assets/image/workflow-m.svg" class="workflow-m" alt="" srcset="">
        <img src="assets/image/workflow-s.svg" class="workflow-s" alt="" srcset="">
    </article>

    <!--End: WorkFlow Section-->

    <!--Start: Work Together Section-->


    <div class="workTogether">
        <div class="heading">
            Let's Work Together.
        </div>
        <div class="content">
            At Bilwg, we pride ourselves on being able to take on any challenge and provide our clients with
            high-quality, innovative solutions. We would love to hear about your vision and learn more about what you
            hope to achieve. Together, we can come up with a proposal for how we can best work together. So click the
            "let's talk" button right now, and we will reach out to you shortly. I understand taking the first step is
            always hard, but we promise that it will be worth your while.
        </div>
        <a href="/#contact">
            <div class="letsTalkButton">
                <div class="letsTalkText">
                    Let's Talk
                </div>
            </div>
        </a>

    </div>
    <!--End: Work Together Section-->

    <hr class="hrLine">

    <!--Start: Quote-->
    <div class="quote">
        Every time you state what you want or believe, you’re the first to hear it. It’s a message to both you and
        others about what you think is possible. Don’t put a ceiling on yourself
    </div>
    <!--End: Quote-->

</div>


<app-footer></app-footer>