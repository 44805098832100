import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ContactModel } from 'src/app/model/contact';
import { ContactService } from 'src/app/services/contact.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor(private contactService: ContactService, private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'A Company provide best Website App and Software Development Services with custom UI, Features and Support, on time, on budget, application development, android app, iOS app development,bilwg services pvt ltd' },
      { name: 'author', content: 'Bilwg Services Pvt Ltd' },
      { name: 'robots', content: 'index, follow' },
      { name: 'date', content: '2023-09-23', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'revisit_after', content: '15 days' },
      { name: 'language', content: 'English' },
      { name: 'keywords', content: 'bilwg,website,app,development,android app development,iOS app development,website development,app development agency,website development agency,best development agency,bilwg services pvt ltd,software development,android app,bilwg services private limited,bilwg website, software developers, iOS app developers,android app developers,software,build website grow business,software development agency, development company,nainital,india,uttarakhand' }
    ]);
    this.title.setTitle('Website, App & Software Development Company - Bilwg Services');
  }

  ngOnInit(): void {

  }

  $error = "";

  contactFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
  });

  onClickSend(): void {
    console.log("Here we goo.")

    // check form validation
    if (this.contactFormGroup.invalid) {
      this.$error = "Please fill all the fields";
      return;
    } else {
      this.$error = "";
    }

    this.contactService.addContactUs({
      name: this.contactFormGroup.value.name,
      email: this.contactFormGroup.value.email,
      description: this.contactFormGroup.value.description,
      status: "Pending",
      phone: this.contactFormGroup.value.phone,
      dataFrom: "Home Page Form",
      timestamp: new Date(),
    } as ContactModel).then(_ => {
      this.contactFormGroup.reset();
    });
  }
}
