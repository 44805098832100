import { Injectable } from '@angular/core';
import { ContactModel } from '../model/contact';
import { environment } from 'src/environment/enviroment';


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  async addContactUs(contact: ContactModel): Promise<void> {

    return new Promise<void>((resolve, reject) => {
      //call an api
      fetch(environment.contactUrl, {
        method: 'POST',
        body: JSON.stringify(contact),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          //check if the response is ok
          if (response.ok) {
            //resolve the promise
            resolve();
          } else {
            //reject the promise
            reject();
          }
        })
        .catch(error => {
          //reject the promise
          reject();
        });
    });
  }
}
