import { Component } from '@angular/core';

@Component({
  selector: 'app-nav-bar-black',
  templateUrl: './nav-bar-black.component.html',
  styleUrls: ['./nav-bar-black.component.scss']
})
export class NavBarBlackComponent {
  isOpen: boolean = false;
}
