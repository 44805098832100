<div class="main-body">

    <app-cursor-tracker></app-cursor-tracker>
    <app-nav-bar-black></app-nav-bar-black>

    <div class="body">
        <section class="header">
            <h1>Legal Policy</h1>
            <p>Navigating the Legal Landscape: Our Policies and Disclaimers</p>
        </section>


        <section class="text">


            <div class="heading">
                1. Introduction
            </div> <br>
            <p>
                Welcome to Bilwg Services Private Limited, a software development company dedicated to providing
                high-quality software
                solutions. This Legal Policy outlines the terms and conditions that govern your use of our website and
                services. By accessing or using our website, you agree to abide by the terms outlined herein.
            </p>

            <br>
            <div class="heading">
                2. Intellectual Property
            </div> <br>


            <ul class="ul">
                <b>2.1. Copyrights: </b> All content, including but not limited to text, graphics, logos, images, and
                software code, is the
                property of Bilwg Services Private Limited and is protected by copyright
                laws. Unauthorized reproduction or distribution is prohibited.
            </ul>
            <br>

            <ul class="ul">
                <b>2.2. Trademarks: </b> All trademarks, service marks, and trade names used on our website are the
                property of Bilwg Services Private Limited.
                Use without explicit permission is strictly prohibited.
            </ul>

            <br>
            <div class="heading">
                3. Use of Website

            </div> <br>

            <ul class="ul">
                <b>3.1. Acceptable Use: </b> You agree to use our website for lawful purposes only. You may not engage
                in any activity that
                disrupts or interferes with the functioning of the website.

            </ul>
            <br>

            <ul class="ul">
                <b>3.2. User Content: </b> Any content submitted by users through our website (comments, reviews, etc.)
                becomes the property of
                Bilwg Services Private Limited, and you grant us the right to use, modify, and distribute such content.

            </ul>

            <br>
            <div class="heading">
                4. Privacy

            </div> <br>

            <ul class="ul">
                <b>4.1. Privacy Policy: </b> Our Privacy Policy, accessible <a href="/privacy-policy">here</a> ,
                outlines how we collect, use, and protect your
                personal information. By using our website, you consent to the practices described therein.

            </ul>

            <br>
            <div class="heading">
                5. Third-Party Links

            </div> <br>

            <ul class="ul">
                <b>5.1. </b> Our website may contain links to third-party websites. Bilwg Services Private Limited is
                not responsible for the content or
                practices of these websites. We recommend reviewing their respective legal policies.

            </ul>

            <br>
            <div class="heading">
                6. Disclaimer of Warranties

            </div> <br>

            <ul class="ul">
                <b>6.1. As-Is Basis: </b> Our website and services are provided on an "as-is" and "as-available" basis
                without any warranties,
                expressed or implied. Bilwg Services Private Limited does not guarantee the accuracy, completeness, or
                reliability of any
                information on the website.

            </ul>

            <br>
            <div class="heading">
                7. Limitation of Liability

            </div> <br>

            <ul class="ul">
                <b>7.1. Exclusion of Damages: </b> Bilwg Services Private Limited shall not be liable for any direct,
                indirect, incidental, consequential, or punitive
                damages arising out of your use of our website or services.

            </ul>

            <br>
            <div class="heading">
                8. Indemnification

            </div> <br>

            <ul class="ul">
                <b>8.1. Indemnity: </b> You agree to indemnify and hold harmless Bilwg Services Private Limited and its
                affiliates from any claims, liabilities,
                damages, or expenses (including legal fees) arising from your use of our website or violation of this
                Legal
                Policy.

            </ul>

            <br>
            <div class="heading">
                9. Changes to Legal Policy

            </div> <br>

            <ul class="ul">
                <b>9.1. </b> Bilwg Services Private Limited reserves the right to update, modify, or replace this Legal
                Policy at any time. The revised
                version will be effective upon posting on the website. Your continued use of the website signifies your
                acceptance of the updated policy.

            </ul>

            <br>
            <div class="heading">
                10. Governing Law and Jurisdiction

            </div> <br>

            <ul class="ul">
                <b>10.1. </b> This Legal Policy shall be governed by and construed in accordance with the laws of
                Uttarakhand, India. Any
                disputes arising from or relating to this policy shall be subject to the exclusive jurisdiction of the
                courts
                in Uttarakhand, India.

            </ul>

            <br>
            <div class="heading">
                11. Contact Information

            </div> <br>

            <ul class="ul">
                <b>11.1. </b> If you have any questions or concerns regarding this Legal Policy, please contact us at <a
                    href="mailto:admin@bilwg.com">admin@bilwg.com</a>.

            </ul>
        </section>
    </div>

    <app-footer></app-footer>
</div>