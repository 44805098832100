<div class="main-body">

  <app-cursor-tracker></app-cursor-tracker>
  <app-nav-bar-black></app-nav-bar-black>

  <div class="body">
    <section class="header">
      <h1>Refund and Cancellation Policy</h1>
      <p><strong>Effective Date:</strong> May 17, 2024</p>
    </section>

    <section class="text">
      <div class="heading">1. Introduction</div>
      <p>Bilwg Services is committed to providing high-quality software as a service (SaaS) products. We understand that sometimes things don't work out as planned. This Refund and Cancellation Policy outlines the circumstances under which you can cancel your subscription and request a refund.</p>
      <br>
      <div class="heading">2. Subscription Cancellation</div>

      <div class="heading">2.1 Cancellation by Customer:</div>
      <p><strong>Monthly Subscriptions:</strong> You may cancel your monthly subscription at any time. Your subscription will remain active until the end of the current billing cycle, after which it will not be renewed.</p>
      <p><strong>Annual Subscriptions:</strong> You may cancel your annual subscription at any time. Your subscription will remain active until the end of the current billing year, after which it will not be renewed.</p>
      <br>
      <div class="heading">2.2 Cancellation Process:</div>
      <p>To cancel your subscription, please log into your account and navigate to the subscription settings page. Follow the prompts to cancel your subscription.</p>
      <p>You may also contact our customer support team at <a href="mailto:admin@bilwg.com">admin@bilwg.com</a> for assistance.</p>
      <br>
      <div class="heading">3. Refund Policy</div>

      <div class="heading">3.1 Refund Eligibility:</div>
      <p><strong>Monthly Subscriptions:</strong> Refunds for monthly subscriptions are not available, as the service is billed on a month-to-month basis.</p>
      <p><strong>Annual Subscriptions:</strong> A prorated refund is available for annual subscriptions if the cancellation request is made within the first 15 days of the subscription period. After 15 days, refunds are not available.</p>
      <br>
      <div class="heading">3.2 Refund Process:</div>
      <p>To request a refund, please contact our customer support team at <a href="mailto:admin@bilwg.com">admin@bilwg.com</a> with your subscription details and reason for cancellation.</p>
      <p>Refund requests will be processed within 10 business days of receipt. Approved refunds will be issued to the original payment method.</p>
      <br>
      <div class="heading">4. Termination by Bilwg Services</div>

      <div class="heading">4.1 Grounds for Termination:</div>
      <p>Bilwg Services reserves the right to suspend or terminate your subscription without prior notice if:</p>
      <ul  class="ul">
        <li>You breach any terms of our Terms of Service.</li>
        <li>You use the service in a manner that could cause harm to Bilwg Services or its users.</li>
        <li>Payment for your subscription is overdue by more than 30 days.</li>
      </ul>
      <br>
      <div class="heading">4.2 Refunds for Termination by Bilwg Services:</div>
      <p>If Bilwg Services terminates your subscription for reasons other than a breach of the Terms of Service by you, a prorated refund will be provided for any remaining subscription period.</p>
      <br>
      <div class="heading">5. Changes to This Policy</div>
      <p>Bilwg Services reserves the right to modify this Refund and Cancellation Policy at any time. Any changes will be effective immediately upon posting to our website. We will notify you of significant changes via email or through our service.</p>
      <br>
      <div class="heading">6. Contact Information</div>
      <p>If you have any questions or concerns about this Refund and Cancellation Policy, please contact us at:</p>
      <br>
      <div class="contact-info">
        <p><strong>Email:</strong> <a href="mailto:admin@bilwg.com">admin@bilwg.com</a></p>
        <p><strong>Phone:</strong> +91-8791923369</p>
        <p><strong>Address:</strong> C/O ANJIL SAH SAJAN LAL, DHUNGSHIL, BHIMTAL,
          NAINITAL, Nainital, Uttarakhand, 263136</p>
      </div>
    </section>
  </div>

  <app-footer></app-footer>
</div>
